import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { DataService } from '@app/services/data.service';
import { ApiResponsePostPasportEntity, PasportEntity, PasportEntityDetails, PasportEntityRelation, PasportTemplateEntity } from '@app/shared/interfaces/pasport-template.interface';

@Component({
  selector: 'app-pasport-device-new-relation',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
  templateUrl: './pasport-device-new-relation.component.html',
  styleUrl: './pasport-device-new-relation.component.css'
})
export class PasportDeviceNewRelationComponent {
  @Input() pasportEntityUuid: string | null = null;

  pasportEntity: PasportEntityDetails | null = null;

  selectedParentControlUuid: string | null = null;

  parentSearch: string = '';
  parentControlSearch: string = '';
  selectedParentUuidManual: string | null = null;

  //pasportEntity: PasportEntity | null = null;
  //relations: PasportEntityRelation[] = [];
  selectedRelationType: string | null = null;
  predefinedHierarchy: {
    entityType: {
      id: number,
      type: string
    },
    pasportEntity: PasportEntity | null
  }[] = [];
  controlEntities: PasportEntity[] = [];
  controlEntitiesManual: PasportEntity[] = [];
  lightnetEntities: PasportEntity[] = [];
  pasportHierarchyControl: PasportEntity[] = [];

  nextPredefinedHierarchyItemType: string | undefined;

  checkboxStatesParentSearch = {
    all: true,
    location: true,
    rvo: true,
    rvoc: true,
    lightline: true,
    output: true,
    lightplace: true,
    lc: true,
    lightpoint: true
  };

  allowedHierarchies = [
    // location
    {
      "type": "control",
      "entityTypeId": 1,
      "hierarchyIds": [1],
      "hierarchyTypes": ["location"]
    },
    // rvo
    {
      "type": "control",
      "entityTypeId": 2,
      "hierarchyIds": [1],
      "hierarchyTypes": ["location"]
    },
    // lightline
    {
      "type": "control",
      "entityTypeId": 3,
      "hierarchyIds": [1, 2],
      "hierarchyTypes": ["location", "rvo"]
    },
    {
      "type": "lightnet",
      "entityTypeId": 3,
      "hierarchyIds": [1, 2, 12],
      "hierarchyTypes": ["location", "rvo", "rvoc"]
    },
    // lightplace
    {
      "type": "control",
      "entityTypeId": 4,
      "hierarchyIds": [1, 2, 3],
      "hierarchyTypes": ["location", "rvo", "lightline"]
    },
    // lightpoint
    {
      "type": "control",
      "entityTypeId": 5,
      "hierarchyIds": [1, 2, 3, 4],
      "hierarchyTypes": ["location", "rvo", "lightline", "lightplace"]
    },
    {
      "type": "lightnet",
      "entityTypeId": 5,
      "hierarchyIds": [1, 2, 12, 3, 13, 14],
      "hierarchyTypes": ["location", "rvo", "rvoc", "lightline", "output", "lc"]
    },
    // lc
    {
      "type": "lightnet",
      "entityTypeId": 14,
      "hierarchyIds": [1, 2, 12, 3, 13],
      "hierarchyTypes": ["location", "rvo", "rvoc", "lightline", "output"]
    }
  ];

  constructor(public dataService: DataService) {}

  // detect pasportEntityUuid change and fetch pasportEntity
  async ngOnChanges(changes: SimpleChanges) {
    if (changes['pasportEntityUuid']) {
      if (this.pasportEntityUuid) {
        this.pasportEntity = await this.dataService.fetchApiGetPasportEntityDetails(this.pasportEntityUuid);
      }
    }
  }

  @Output() onNewRelation: EventEmitter<void> = new EventEmitter<void>();
  async createRelationPredefined() {
    // get last item that has pasportEntity defined
    const lastItemIndex = this.predefinedHierarchy.reduceRight((acc, item, index) => {
      return acc === -1 && item.pasportEntity !== null ? index : acc;
    }, -1);

    const lastUuid = this.predefinedHierarchy[lastItemIndex]?.pasportEntity?.uuid;

    if (this.selectedRelationType && this.pasportEntityUuid && lastUuid) {
      const newRelationResult = await this.dataService.fetchApiPostPasportRelations(lastUuid, this.pasportEntityUuid, this.selectedRelationType);
      // new relation has been created
      if (newRelationResult && (newRelationResult.id >= 0)) {
        // emit function to refresh relations
        this.onNewRelation.emit();
      }
      // if (newEntityResult && newEntityResult.id) {
      //   this.relations.push({
      //     id: newEntityResult.id,
      //     ancestor: newEntityResult.ancestor,
      //     descendant: newEntityResult.descendant,
      //     type: newEntityResult.type
      //   });
      // }

      // reset search input
      this.parentSearch = '';
      this.parentControlSearch = '';

      // reset hierarchy
      this.controlEntities = [];
      this.controlEntitiesManual = [];
      this.pasportHierarchyControl = [];
      this.addPasportHierarchyControlItem();

      this.predefinedHierarchy = [];
      this.selectedRelationType = null;
      this.onPredefinedRelationTypeChange(null);
    }
  }

  async addPasportHierarchyControlItem() {
    console.log('Adding pasport hierarchy control item', this.selectedParentControlUuid);

    this.parentControlSearch = '';
    this.lightnetEntities = [];

    if (this.controlEntities.length <= 0) {
      this.controlEntities = [];
      // FIXME: hardcoded id
      const result = await this.dataService.fetchApiGetPasportRelationsDescendants(1);
      this.controlEntities = result.descendants;
    } else {
      // clear control hierarchy
      // this.pasportHierarchyControl = []
      // add selected item to pasport hierarchy control array
      const selectedItem = this.controlEntities.find(item => item.uuid === this.selectedParentControlUuid);
      if (selectedItem) {
        this.pasportHierarchyControl.push(selectedItem);
      }
      this.controlEntities = [];
      // FIXME: hardcoded types
      const result = await this.dataService.fetchApiGetPasportRelationsDescendants(this.pasportHierarchyControl[this.pasportHierarchyControl.length - 1].id);
      this.controlEntities = result.descendants;
      this.selectedParentControlUuid = null;
    }
  }

  // Update your existing method to use the new function
  async onPredefinedRelationTypeChange(event: any) {
    // TODO: remove hardcoded 5 after testing
    const newEntityTypeId = this.pasportEntity?.entity?.entityType?.id; // Fallback to 5 if not set
    const relationType = this.selectedRelationType;

    if (!relationType) {
      this.predefinedHierarchy = [];
      return;
    }

    // find allowed hierarchies from config array allowedHierarchies
    const allowedHierarchies = this.allowedHierarchies.filter(hierarchy => hierarchy.type === relationType && hierarchy.entityTypeId === newEntityTypeId);
    if (!(allowedHierarchies.length > 0)) {
      console.error('No allowed hierarchies found');
      this.predefinedHierarchy = [];
      return;
    }

    this.controlEntities = [];
    this.predefinedHierarchy = [];
    // TODO: what to do if there are multiple allowed hierarchies for a specific entity type?
    // loop allowedHierarchies and construct predefined hierarchy array
    // TODO: hardcoded first allowed hierarchy
    for (let i = 0; i < allowedHierarchies[0].hierarchyIds.length; i++) {
      const entityTypeId = allowedHierarchies[0].hierarchyIds[i];
      const entityType = allowedHierarchies[0].hierarchyTypes[i];
      this.predefinedHierarchy.push({
        entityType: {
          id: entityTypeId,
          type: entityType
        },
        pasportEntity: null
      });
    }

    this.setNextPredefinedHierarchyItem();
    this.addPasportEntityToPredefinedHierarchy();
  }

  setNextPredefinedHierarchyItem() {
    let lastFilledIndex = -1;
    for (let i = this.predefinedHierarchy.length - 1; i >= 0; i--) {
      if (this.predefinedHierarchy[i].pasportEntity !== null) {
        lastFilledIndex = i;
        break;
      }
    }

    const nextIndex = lastFilledIndex + 1;
    if (nextIndex < this.predefinedHierarchy.length) {
      this.nextPredefinedHierarchyItemType = this.predefinedHierarchy[nextIndex].entityType.type;
    } else {
      this.nextPredefinedHierarchyItemType = undefined;
    }
  }

  async addPasportEntityToPredefinedHierarchy() {
    console.log('Adding pasport entity to predefined hierarchy', this.selectedParentControlUuid);

    if (this.controlEntities.length <= 0) {
      this.controlEntities = [];
      // FIXME: hardcoded id
      const result = await this.dataService.fetchApiGetPasportRelationsDescendants(1);
      this.controlEntities = result.descendants;
      this.setNextPredefinedHierarchyItem();
    } else {
      // find selected item
      const selectedItem = this.controlEntities.find(item => item.uuid === this.selectedParentControlUuid);
      if (selectedItem) {
        // try to find item in predefined hierarchy by entity type
        const predefinedItem = this.predefinedHierarchy.find(item => item.entityType.type === selectedItem.entityType.type);
        if (predefinedItem) {
          predefinedItem.pasportEntity = selectedItem;

          this.controlEntities = [];
          // FIXME: hardcoded types
          const result = await this.dataService.fetchApiGetPasportRelationsDescendants(predefinedItem.pasportEntity.id);
          this.controlEntities = result.descendants;
        }
      }

      this.selectedParentControlUuid = null;
      this.setNextPredefinedHierarchyItem();
    }
  }

  async resetPredefinedHierarchyItem(itemIndex: number | null) {
    console.log('Resetting predefined hierarchy item');
    console.log('itemIndex', itemIndex);
    // reset item at index and all items after it
    if (itemIndex !== null && itemIndex >= 0) {
      for (let i = itemIndex; i < this.predefinedHierarchy.length; i++) {
        this.predefinedHierarchy[i].pasportEntity = null;
      }
    }

    // find last item in predefined hierarchy that has pasportEntity
    const lastItemIndex = this.predefinedHierarchy.reduceRight((acc, item, index) => {
      return acc === -1 && item.pasportEntity !== null ? index : acc;
    }, -1);

    console.log('lastItemIndex', lastItemIndex);

    if (lastItemIndex !== -1 && this.predefinedHierarchy[lastItemIndex].pasportEntity) {
      console.log('this.predefinedHierarchy[lastItemIndex].pasportEntity', this.predefinedHierarchy[lastItemIndex].pasportEntity);
      const id = this.predefinedHierarchy[lastItemIndex]?.pasportEntity?.id;
      if (id) {
        const result = await this.dataService.fetchApiGetPasportRelationsDescendants(id);
        this.controlEntities = result.descendants;
      }
    } else {
      this.controlEntities = [];
      // FIXME: hardcoded id
      const result = await this.dataService.fetchApiGetPasportRelationsDescendants(1);
      this.controlEntities = result.descendants;
    }

    this.setNextPredefinedHierarchyItem();
  }

  async searchControlEntitiesManual() {
    console.log('Searching control entities', this.parentControlSearch);
    let types = '';
    // TODO: .all not used anymore?
    if (this.checkboxStatesParentSearch.all) {
      types = 'lightplace,lightpoint,trafficCounter,other,phase,breaker,rvoc,output,lc,location,rvo,lightline,crossing,twilightSwitch';
    } else {
      if (this.checkboxStatesParentSearch.location) {
        types += 'location,';
      }
      if (this.checkboxStatesParentSearch.rvo) {
        types += 'rvo,';
      }
      if (this.checkboxStatesParentSearch.rvoc) {
        types += 'rvoc,';
      }
      if (this.checkboxStatesParentSearch.lightline) {
        types += 'lightline,';
      }
      if (this.checkboxStatesParentSearch.output) {
        types += 'output,';
      }
      if (this.checkboxStatesParentSearch.lc) {
        types += 'lc,';
      }
      if (this.checkboxStatesParentSearch.lightplace) {
        types += 'lightplace,';
      }
      if (this.checkboxStatesParentSearch.lightpoint) {
        types += 'lightpoint,';
      }
      // remove last comma
      types = types.slice(0, -1);
    }

    this.controlEntitiesManual = [];
    // FIXME: hardcoded types
    const params = {
      types: types,
      jsonSearchCriteriaList: {
        "dataOption": "all",
        "searchCriteriaList": [
          { "filterKey": "name", "operation": "cn", "value": this.parentControlSearch }
        ]
      }
    };
    const result = await this.dataService.fetchApiGetPasportEntitiesByParams(params);
    console.log('result', result);
    this.controlEntitiesManual = result.entities.content;
  }

  async addPasportHierarchyPredefinedItemManual() {
    this.parentControlSearch = '';

    // clear hierarchy
    //this.pasportHierarchyControl = []

    // remove all entity items from predefined hierarchy
    for (const item of this.predefinedHierarchy) {
      item.pasportEntity = null;
    }

    // add selected item to pasport hierarchy control array
    const selectedItem = this.controlEntitiesManual.find(item => item.uuid === this.selectedParentUuidManual);

    // we have found item
    if (selectedItem) {
      // try to fit selected item into predefined hierarchy
      const predefinedItem = this.predefinedHierarchy.find(item => item.entityType.type === selectedItem.entityType.type);
      if (predefinedItem) {
        predefinedItem.pasportEntity = selectedItem;

        // recreate control hierarchy to fetch ancestors of added item in loop
        let result = await this.dataService.fetchApiGetPasportRelationsAncestors(selectedItem.id);
        let counter = 0;
        while (result.ancestors.length > 0 && counter < 20) {
          counter++;
          // add all ancestors to the top of the array
          for (const ancestor of result.ancestors) {
            // try to fit ancestor into predefined hierarchy
            const predefinedAncestor = this.predefinedHierarchy.find(item => item.entityType.type === ancestor.entityType.type);
            if (predefinedAncestor) {
              predefinedAncestor.pasportEntity = ancestor;
            }
          }

          if (result.ancestors[0].entityType.type === 'location') {
            break;
          }
          result = await this.dataService.fetchApiGetPasportRelationsAncestors(result.ancestors[0].id);
        }
      }
    }

    // // cleanup for next use
    this.controlEntities = [];
    this.resetPredefinedHierarchyItem(null);
  }

}
