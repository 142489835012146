@if (!newEntity) {
<!-- Control parent for the device -->
<div class="p-2 rounded-lg mb-2 bg-gray-50 dark:bg-gray-800">
  <div class="">
    <select [(ngModel)]="selectedTemplateId" (ngModelChange)="onTemplateChange($event)"
      class="disabled:cursor-not-allowed text-sm rounded-lg border  block w-full p-2.5
          bg-white dark:bg-gray-700 text-gray-900 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500">
      <option [ngValue]="null" disabled>Zvoľte typ zariadenia</option>
      @for (item of templates; track item.id; let i = $index) {
      <option [ngValue]="item.id">{{ dataService.formatEntityType(item.name) }}</option>
      }
    </select>
  </div>
</div>

<!-- TODO: make this a separate component -->
<div class="mb-8 bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
  <table class="w-full text-sm text-left rtl:text-right">
    <thead class="text-xs uppercase bg-gray-50 border-b border-gray-200 ">
      <tr>
        <th scope="col" class="px-2 py-3">
          Parametre
        </th>
        <th scope="col" class="px-2 py-3">
          &nbsp;
        </th>
      </tr>
    </thead>
    <tbody>
      @for (item of objectEntries(selectedTemplate?.data?.params); track item[0]) {
      <tr class="bg-gray-50 border-b border-gray-200">
        <th scope="row" class="px-2 py-2 font-medium">
          {{ item[1].translations[0].translation }}
        </th>
        <td class="px-2 py-2">
          <ng-container [ngSwitch]="item[1].valueType">
            <ng-container *ngSwitchCase="'text'">
              <input type="text" [(ngModel)]="paramValues[item[0]]"
                class="px-2 py-1 rounded
              bg-white border text-gray-900 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500">
            </ng-container>
            <ng-container *ngSwitchCase="'number'">
              <input type="number" [(ngModel)]="paramValues[item[0]]"
                class="px-2 py-1 rounded
              bg-white border text-gray-900 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500">
            </ng-container>
            <ng-container *ngSwitchCase="'Boolean'">
              <input type="checkbox" [(ngModel)]="paramValues[item[0]]"
                class="rounded bg-white border text-gray-900 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500">
            </ng-container>
            <ng-container *ngSwitchCase="'timestamp'">
              <input type="datetime-local" [(ngModel)]="paramValues[item[0]]"
                class="px-2 py-1 rounded
                bg-white border text-gray-900 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500">
            </ng-container>
            <ng-container *ngSwitchDefault>
              <input type="text" [(ngModel)]="paramValues[item[0]]"
                class="px-2 py-1 rounded
              bg-white border text-gray-900 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500">
            </ng-container>
          </ng-container>
        </td>
      </tr>
      }
    </tbody>
  </table>
</div>

<div class="flex justify-center mt-8 mb-4">
  <button [disabled]="(!isValid)" type="button" (click)="createDevice()" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
    disabled:bg-blue-400 disabled:dark:bg-blue-500 disabled:cursor-not-allowed">
    Vytvoriť zariadenie - Krok 1/2</button>
</div>
}




@if (newEntity) {
<div class="p-2 rounded-lg mb-2 bg-gray-50 dark:bg-gray-800 flex flex-col">
  <div><b>Typ zariadenia:</b> {{ dataService.formatEntityType(newEntity.entityType.type) }}</div>
  <div><b>Názov:</b> {{ newEntity.data['name'] }}</div>
  <div><b>UUID:</b> {{ newEntity.uuid }}</div>
</div>

<div class="p-2 rounded-lg mb-4 bg-gray-50 dark:bg-gray-800 flex flex-col">
  @if (relations && relations.length > 0) {
  <div><b>Väzby zariadenia:</b></div>
  <div>
    @for (relation of relations; track relation.id) {
    <div class="flex">
      <div class="flex-1">
        <b>{{ relation.type }}</b>:
        {{ dataService.formatEntityType(relation.ancestor.entityType.type) }} {{ relation.ancestor.data['name'] }} -> {{ dataService.formatEntityType(relation.descendant.entityType.type) }} {{ relation.descendant.data['name'] }}
      </div>
      <div class="flex-none items-center justify-center mt-2">
        <button type="button" (click)="deleteRelation(relation.id)"
          class="px-3 py-2.5 text-base font-medium text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          <svg class="w-6 h-6 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
            viewBox="0 0 448 512">
            <path
              d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0L284.2 0c12.1 0 23.2 6.8 28.6 17.7L320 32l96 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 96C14.3 96 0 81.7 0 64S14.3 32 32 32l96 0 7.2-14.3zM32 128l384 0 0 320c0 35.3-28.7 64-64 64L96 512c-35.3 0-64-28.7-64-64l0-320zm96 64c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16l0 224c0 8.8 7.2 16 16 16s16-7.2 16-16l0-224c0-8.8-7.2-16-16-16z" />
          </svg>
        </button>
      </div>
    </div>
    }
  </div>
  } @else {
  <div>Zariadenie zatiaľ nemá žiadne väzby</div>
  }
</div>

<div class="bg-gray-100 p-2 rounded-lg mt-8 mb-8">
  <div class="flex flex-col">
    @if (selectedRelationType) {
    <div class="flex-1 justify-center mb-2">
      <button type="button" (click)="createRelationPredefined()"
        class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        <span>Potvrdiť prepojenine na posledný prvok v hierarchii</span>
      </button>
    </div>
    }

    <div class="flex-1">
      <select [(ngModel)]="selectedRelationType"
      (ngModelChange)="onPredefinedRelationTypeChange($event)"
        class="disabled:cursor-not-allowed text-sm rounded-lg border  block w-full p-2.5
            bg-white dark:bg-gray-700 text-gray-900 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option [ngValue]="null" disabled>Vyberte typ väzby</option>
        <option [ngValue]="'control'" >Rodičovské zariadenie - fyzické umiestnenie (control)</option>
        <option [ngValue]="'lightnet'" >Nadradené riadiace zariadenie (lightnet)</option>
      </select>
    </div>
  </div>

  @if (selectedRelationType) {
  <div class="">
    @if (predefinedHierarchy && predefinedHierarchy.length > 0) {
    <ol class="relative ml-2 mt-4 mb-4 text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400">
      @for (item of predefinedHierarchy; track $index) {
      <li class="mb-3 ms-6">
        <span
          class="absolute flex items-center justify-center w-3 h-3 bg-gray-200 rounded-full -start-1.5 ring-2 ring-white dark:ring-gray-900 dark:bg-gray-700">
        </span>
        @if (item.pasportEntity) {
        <h3 class="font-medium leading-tight">
          <a href="javascript:void(0)"
              (click)="resetPredefinedHierarchyItem($index)"
              class="hover:underline cursor-pointer">
              {{ dataService.formatEntityType(item?.pasportEntity?.entityType?.type) }} {{ item.pasportEntity.data['name'] }}
          </a>
        </h3>
        <p class="text-sm">{{ item.pasportEntity.data['address'] }}</p>
        <p class="text-sm">{{ item.pasportEntity.uuid }}</p>
        } @else {
        <h3 class="font-medium leading-tight opacity-50">
          <a href="javascript:void(0)"
             (click)="resetPredefinedHierarchyItem($index)"
             class="hover:underline cursor-pointer">
            {{ dataService.formatEntityType(item?.entityType?.type) }}
          </a>
        </h3>
        }
      </li>
      }
    </ol>
    }
    <div class="mt-2">
      <select [(ngModel)]="selectedParentControlUuid" (ngModelChange)="addPasportEntityToPredefinedHierarchy()"
        class="disabled:cursor-not-allowed text-sm rounded-lg border  block w-full p-2.5
            bg-white dark:bg-gray-700 text-gray-900 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option [ngValue]="null" disabled>Vyberte ďalší prvok: {{ dataService.formatEntityType(nextPredefinedHierarchyItemType) }} </option>
        @for (item of controlEntities; track item.uuid; let i = $index) {
        <option [ngValue]="item.uuid">{{ dataService.formatEntityType(item.entityType.type) }} {{ item.data['name'] }}
        </option>
        }
      </select>
    </div>
  </div>
  }
</div>

@if (selectedRelationType) {
<div class="p-2 rounded-lg mb-1 bg-gray-50 dark:bg-gray-800 flex flex-col">
  <div class="w-full">
    <div class="flex">
      <div class="relative flex-1">
        <input type="search" [(ngModel)]="parentControlSearch"
          (keyup.enter)="searchControlEntitiesManual()"
          class="disabled:cursor-not-allowed text-sm rounded-lg block w-full p-2.5
            bg-white border text-gray-900 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Vyhľadávanie všetkých zariadení ..." required>
      </div>
    </div>
  </div>

  <div class="flex flex-wrap gap-2 p-2">
    <div class="flex items-center me-4">
      <input [(ngModel)]="checkboxStatesParentSearch.location" checked id="pasport-new-device-parent-type-location" type="checkbox" value="location"
        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
      <label for="pasport-new-device-parent-type-location"
        class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Lokalita</label>
    </div>
    <div class="flex items-center me-4">
      <input [(ngModel)]="checkboxStatesParentSearch.rvo" checked id="pasport-new-device-parent-type-rvo" type="checkbox" value="rvo"
        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
      <label for="pasport-new-device-parent-type-rvo"
        class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Rozvádzač</label>
    </div>
    <div class="flex items-center me-4">
      <input [(ngModel)]="checkboxStatesParentSearch.rvoc" checked id="pasport-new-device-parent-type-rvoc" type="checkbox" value="rvoc"
        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
      <label for="pasport-new-device-parent-type-rvoc"
        class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Rvoc</label>
    </div>
    <div class="flex items-center me-4">
      <input [(ngModel)]="checkboxStatesParentSearch.lightline" checked id="pasport-new-device-parent-type-lightline" type="checkbox" value="lightline"
        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
      <label for="pasport-new-device-parent-type-lightline"
        class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Vetva</label>
    </div>
    <div class="flex items-center me-4">
      <input [(ngModel)]="checkboxStatesParentSearch.output" checked id="pasport-new-device-parent-type-output" type="checkbox" value="output"
        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
      <label for="pasport-new-device-parent-type-output"
        class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Output</label>
    </div>
    <div class="flex items-center me-4">
      <input [(ngModel)]="checkboxStatesParentSearch.lightplace" checked id="pasport-new-device-parent-type-lightplace" type="checkbox" value="lightplace"
        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
      <label for="pasport-new-device-parent-type-lightplace"
        class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Stožiar</label>
    </div>
    <div class="flex items-center me-4">
      <input [(ngModel)]="checkboxStatesParentSearch.lc" checked id="pasport-new-device-parent-type-lc" type="checkbox" value="lc"
        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
      <label for="pasport-new-device-parent-type-lc"
        class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">LC</label>
    </div>
    <div class="flex items-center me-4">
      <input [(ngModel)]="checkboxStatesParentSearch.lightpoint" checked id="pasport-new-device-parent-type-lightpoint" type="checkbox" value="lightpoint"
        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
      <label for="pasport-new-device-parent-type-lightpoint"
        class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Svietidlo</label>
    </div>
  </div>

  <div class="mb-2">
    <select [(ngModel)]="selectedParentUuidManual" size="7"
      class="disabled:cursor-not-allowed text-sm rounded-lg border  block w-full p-2.5
          bg-white dark:bg-gray-700 text-gray-900 dark:placeholder-gray-400 dark:text-white border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500">
      @for (item of controlEntitiesManual; track item.uuid; let i = $index) {
      <option [ngValue]="item.uuid">{{ dataService.formatEntityType(item.entityType.type) }} {{ item.data['name'] }}
      </option>
      }
    </select>
  </div>

  <div class="flex justify-center flex-col">
    <button type="button" (click)="addPasportHierarchyPredefinedItemManual()"
      class="flex justify-center focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm p-2.5 text-center items-center dark:focus:ring-gray-800 text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white disabled:bg-blue-400 disabled:dark:bg-blue-500 disabled:cursor-not-allowed ">
      Použiť zvolený prvok pre väzbu
    </button>
  </div>
</div>
}

}

<!-- Bottom of the page to add some space for bottom navigation -->
<div>
  <br /><br /><br /><br /><br /><br /><br />
</div>