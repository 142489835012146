<!-- Sidebar / Main menu - Left / Bottom
  TODO: move to separate component
-->
<!-- <div class="absolute w-16 h-full z-50 bg-white border-r border-gray-300 shadow-lg"> -->
<div class="absolute bottom-0 w-full border-t border-gray-300 shadow-upper-lg shadow-lg  bg-white z-90
  md:w-16 md:h-full md:border-r">
  <!-- <ul class="py-5 px-3"> -->
  <ul class="pt-3 pb-4 px-3 flex justify-around md:py-5 md:px-3 md:block ">
    <li class="md:mb-6">
      <a href="#" data-tooltip-target="sidebar-toolbar-lia" data-tooltip-placement="right"
        class="duration-75 text-white font-semibold p-2 rounded-lg items-center flex bg-gray-800 dark:text-white hover:bg-gray-100 hover:text-gray-600 dark:hover:bg-gray-700 group"
        (click)="sidebarIconToggleSection('main-menu-drawer'); $event.preventDefault()">
        LIA
      </a>
      <div id="sidebar-toolbar-lia" role="tooltip"
        class="absolute z-10 invisible hidden md:inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
        LIA - Light Issue Assistant
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </li>

    <li class="md:mb-6">
      <a href="#" data-tooltip-target="sidebar-toolbar-main-menu" data-tooltip-placement="right"
        class="duration-75 text-gray-600 p-2 rounded-lg items-center flex dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
        (click)="toggleDrawer('drawer-menu'); $event.preventDefault()">
        <svg class="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path
            d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
        </svg>
      </a>
      <div id="sidebar-toolbar-main-menu" role="tooltip"
        class="absolute z-10 invisible hidden md:inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
        Hlavné menu
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </li>

    <li class="md:mb-1">
      <a href="#" data-tooltip-target="sidebar-toolbar-map" data-tooltip-placement="right"
        class="duration-75 text-gray-600 p-2 rounded-lg items-center flex dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
        (click)="toggleDrawer('drawer-map'); $event.preventDefault()">
        <svg class="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path
            d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
        </svg>
      </a>
      <div id="sidebar-toolbar-map" role="tooltip"
        class="absolute z-10 invisible hidden md:inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
        Vyhľadávanie na mape
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </li>

    <li class="md:mb-1">
      <a href="#" data-tooltip-target="sidebar-toolbar-filter" data-tooltip-placement="right"
        class="duration-75 text-gray-600 p-2 rounded-lg items-center flex dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
        (click)="toggleDrawer('drawer-filter'); $event.preventDefault()">
        <svg class="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z" />
        </svg>
      </a>
      <div id="sidebar-toolbar-filter" role="tooltip"
        class="absolute z-10 invisible hidden md:inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
        Filter
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </li>

    <li class="md:mb-1">
      <a href="#" data-tooltip-target="sidebar-toolbar-entities-table" data-tooltip-placement="right"
        class="duration-75 text-gray-600 p-2 rounded-lg items-center flex dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
        (click)="toggleDrawer('drawer-table'); $event.preventDefault()">
        <svg class="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm64 0v64h64V96H64zm384 0H192v64H448V96zM64 224v64h64V224H64zm384 0H192v64H448V224zM64 352v64h64V352H64zm384 0H192v64H448V352z" />
        </svg>
      </a>
      <div id="sidebar-toolbar-entities-table" role="tooltip"
        class="absolute z-10 invisible hidden md:inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
        Tabuľka
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </li>

    <li class="md:mb-1">
      <a href="#" data-tooltip-target="sidebar-toolbar-refresh" data-tooltip-placement="right"
        class="relative text-gray-600 dark:text-white duration-75 p-2 rounded-lg items-center flex hover:bg-gray-100 dark:hover:bg-gray-700 group"
        (click)="refreshData(); $event.preventDefault()">
        <svg class="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" />
        </svg>
        @if (incidentsChangeDetected) {
        <div
          class="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">
          &nbsp;</div>
        }
      </a>
      <div id="sidebar-toolbar-refresh" role="tooltip"
        class="absolute z-10 invisible hidden md:inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
        Aktualizovať údaje
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </li>

    <li class="md:mb-1">
      <a href="#" data-tooltip-target="sidebar-toolbar-dashboard" data-tooltip-placement="right"
        class="duration-75 text-gray-600 p-2 rounded-lg items-center flex dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
        (click)="toggleDrawer('drawer-dashboard'); $event.preventDefault()">
        <svg class="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path
            d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zm64 192c17.7 0 32 14.3 32 32l0 96c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-96c0-17.7 14.3-32 32-32zm64-64c0-17.7 14.3-32 32-32s32 14.3 32 32l0 192c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-192zM320 288c17.7 0 32 14.3 32 32l0 32c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-32c0-17.7 14.3-32 32-32z" />
        </svg>
      </a>
      <div id="sidebar-toolbar-dashboard" role="tooltip"
        class="absolute z-10 invisible hidden md:inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
        Reporty
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </li>

    <li class="md:mb-1">
      <a href="#" data-tooltip-target="sidebar-toolbar-notifications" data-tooltip-placement="right"
        class="duration-75 text-gray-600 p-2 rounded-lg items-center flex dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
        (click)="toggleDrawer('drawer-messages'); $event.preventDefault()">
        <svg class="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h96v80c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64z" />
        </svg>
      </a>
      <div id="sidebar-toolbar-notifications" role="tooltip"
        class="absolute z-10 invisible hidden md:inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
        Notifikácie
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </li>
  </ul>
</div>

<div id="map" class=""></div>

@if (!(isListVisible === true)) {
<div class="
  absolute left-1/2 transform -translate-x-1/2 top-2 z-20
  py-2 px-4 flex justify-center rounded-lg shadow
  bg-gray-50 border border-gray-200 dark:bg-gray-700 dark:border-gray-700">
  <div class="flex items-center mr-4">
    <input [(ngModel)]="mapboxSelectedLayer" (ngModelChange)="onMapboxSelectedLayerChange($event)" checked
      id="default-radio-1" type="radio" value="incidents" name="default-radio"
      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
    <label for="default-radio-1"
      class="flex items-center ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 whitespace-nowrap">
      Poruchy
      @if (fetchApiGetIncidentsProcessing | async) {
      <svg aria-hidden="true" role="status" class="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101"
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="#E5E7EB" />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentColor" />
      </svg>
      } @else {
      ({{ incidentsCount }})
      }
    </label>
  </div>
  <div class="flex items-center mr-4">
    <input [(ngModel)]="mapboxSelectedLayer" (ngModelChange)="onMapboxSelectedLayerChange($event)" id="default-radio-2"
      type="radio" value="pasport" name="default-radio"
      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
    <label for="default-radio-2"
      class="flex items-center ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 whitespace-nowrap">
      Pasport
      @if (fetchApiGetPasportEntitiesProcessing | async) {
      <svg aria-hidden="true" role="status" class="inline w-4 h-4 ml-2 text-white animate-spin" viewBox="0 0 100 101"
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="#E5E7EB" />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentColor" />
      </svg>
      } @else {
      ({{ pasportEntitiesCount }})
      }
    </label>
  </div>
</div>
}

<div [class.max-h-screen-s]="isListVisible" class="absolute top-0 z-10 w-full px-3 flex flex-col">
  <!-- <div class="flex flex-col h-screen absolute top-0 w-full px-3 pt-3 pb-10"> -->

  <!-- top panel -->
  @if (false) {
  <div class="flex gap-2 max-w-xl">
    <div class="flex-none">
    </div>
    <!-- <div class="flex-auto flex justify-end"> -->
    <div class="flex-auto">
      <!-- toggle list button -->
      <button type="button" (click)="toggleVisibility('list')"
        class="w-full inline-flex items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
        <svg class="w-3 h-3 text-white me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 512 512">
          <path
            d="M24 56c0-13.3 10.7-24 24-24H80c13.3 0 24 10.7 24 24V176h16c13.3 0 24 10.7 24 24s-10.7 24-24 24H40c-13.3 0-24-10.7-24-24s10.7-24 24-24H56V80H48C34.7 80 24 69.3 24 56zM86.7 341.2c-6.5-7.4-18.3-6.9-24 1.2L51.5 357.9c-7.7 10.8-22.7 13.3-33.5 5.6s-13.3-22.7-5.6-33.5l11.1-15.6c23.7-33.2 72.3-35.6 99.2-4.9c21.3 24.4 20.8 60.9-1.1 84.7L86.8 432H120c13.3 0 24 10.7 24 24s-10.7 24-24 24H32c-9.5 0-18.2-5.6-22-14.4s-2.1-18.9 4.3-25.9l72-78c5.3-5.8 5.4-14.6 .3-20.5zM224 64H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 160H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 160H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32z" />
        </svg>
        Poruchy ({{ incidentsCount }})&nbsp;&nbsp;
        <!-- <svg class="w-3.5 h-3.5 text-white me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 576 512">
          <path
            d="M64 112c-8.8 0-16 7.2-16 16V384c0 8.8 7.2 16 16 16H512c8.8 0 16-7.2 16-16V128c0-8.8-7.2-16-16-16H64zM0 128C0 92.7 28.7 64 64 64H512c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM176 320H400c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V336c0-8.8 7.2-16 16-16zm-72-72c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H120c-8.8 0-16-7.2-16-16V248zm16-96h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H120c-8.8 0-16-7.2-16-16V168c0-8.8 7.2-16 16-16zm64 96c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H200c-8.8 0-16-7.2-16-16V248zm16-96h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H200c-8.8 0-16-7.2-16-16V168c0-8.8 7.2-16 16-16zm64 96c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H280c-8.8 0-16-7.2-16-16V248zm16-96h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H280c-8.8 0-16-7.2-16-16V168c0-8.8 7.2-16 16-16zm64 96c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H360c-8.8 0-16-7.2-16-16V248zm16-96h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H360c-8.8 0-16-7.2-16-16V168c0-8.8 7.2-16 16-16zm64 96c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H440c-8.8 0-16-7.2-16-16V248zm16-96h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H440c-8.8 0-16-7.2-16-16V168c0-8.8 7.2-16 16-16z" />
        </svg> -->
      </button>
    </div>
    <div class="">
      <!-- TODO: add function to refresh data refresh button -->
      <button type="button" (click)="refreshData()" [ngClass]="{
          'bg-blue-700 hover:bg-blue-800 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800': !incidentsChangeDetected,
          'bg-green-500 hover:bg-green-600 focus:ring-green-300 dark:bg-green-400 dark:hover:bg-green-500 dark:focus:ring-green-600': incidentsChangeDetected
        }"
        class="inline-flex items-center text-white focus:ring-4 font-medium rounded-lg text-sm px-4 py-2.5 focus:outline-none">
        &nbsp;
        <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 512 512">
          <path
            d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" />
        </svg>
        &nbsp;
      </button>
    </div>
    <div class="">
      <!-- filter button -->
      <button type="button" (click)="toggleVisibility('filter')"
        class="inline-flex items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
        &nbsp;
        <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 512 512">
          <path
            d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z" />
        </svg>
        &nbsp;
      </button>
    </div>
  </div>
  }

  <!-- filter component -->
  <!-- @if (isFilterVisible) { -->
  @if (false) {
  <div class="mt-2 py-2 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600">
    <app-filter (onFilterChange)="handleFilterChange()" (onFilterWithInitChange)="handleFilterWithInitChange()"></app-filter>
  </div>
  }

  <!-- search address component -->
  <!-- @if (!((isListVisible === true) || (isFilterVisible === true))) { -->
  @if (false) {
  <form class="max-w-xl mt-2 mb-1" [formGroup]="searchForm" (ngSubmit)="searchAddress()">
    <label for="input-search-address"
      class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
    <div class="relative">
      <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512">
          <path
            d="M256 0c17.7 0 32 14.3 32 32V66.7C368.4 80.1 431.9 143.6 445.3 224H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H445.3C431.9 368.4 368.4 431.9 288 445.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.3C143.6 431.9 80.1 368.4 66.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V32c0-17.7 14.3-32 32-32zM128 256a128 128 0 1 0 256 0 128 128 0 1 0 -256 0zm128-80a80 80 0 1 1 0 160 80 80 0 1 1 0-160z" />
        </svg>
      </div>
      <input type="search" [formControl]="inputMapSearchAddress" id="input-serach-address"
        class="block w-full px-4 py-3 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Zadajte adresu ..." required>
      <button type="submit"
        class="text-white absolute end-2.5 bottom-2.5 bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-xs px-2.5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
        <svg class="w-3 h-3 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
          viewBox="0 0 512 512">
          <path
            d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
        </svg>
      </button>
    </div>

    @if (geocoderResults.length > 0) {
    <div id="searchAddressResults" class="bg-white rounded-lg shadow dark:bg-gray-700 mt-2 py-2 pr-2 w-full">
      <ul class="max-h-48 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
        aria-labelledby="dropdownSearchButton">
        @for (item of geocoderResults; track item.id) {
        <li (click)="gotoAddress(item)">
          <div class="flex items-center ps-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
            <label class="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">{{
              item.matching_place_name }}</label>
          </div>
        </li>
        }
      </ul>
    </div>
    }
  </form>
  }

  <!-- search fulltextxcomponent -->
  <!-- @if ((isListVisible === true) || (isFilterVisible === true)) { -->
  @if (isListVisible === true) {
  <form class="md:hidden mt-3 mb-1" [formGroup]="searchForm">
    <label for="input-search-fulltext"
      class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
    <div class="relative">
      <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
          fill="none" viewBox="0 0 20 20">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
        </svg>
      </div>
      <input type="search" [formControl]="inputMapFilterFulltext" id="input-search-fulltext"
        class="block w-full px-4 py-3 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Vyhľadávanie ..." required>
      @if (mapboxSelectedLayer === 'incidents') {
      <div class="absolute inset-y-0 end-2 flex items-center">
        <button type="submit" (click)="prevPage()"
          class="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-xs px-2.5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
          <svg class="w-3 h-3 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
            viewBox="0 0 320 512">
            <path
              d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
          </svg>
        </button>

        <select [formControl]="selectIncidentsPaging" id="select-incidents-paging"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-1.5 mx-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option *ngFor="let option of searchIncidentsPages" [value]="option.value">
            {{ option.label }}
          </option>
        </select>

        <button type="submit" (click)="nextPage()"
          class="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-xs px-2.5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
          <svg class="w-3 h-3 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
            viewBox="0 0 320 512">
            <path
              d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
          </svg>
        </button>
      </div>
      }
      @if (mapboxSelectedLayer === 'pasport') {
      <div class="absolute inset-y-0 end-2 flex items-center">
        <button type="submit" (click)="prevPagePasportEntities()"
          class="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-xs px-2.5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
          <svg class="w-3 h-3 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
            viewBox="0 0 320 512">
            <path
              d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
          </svg>
        </button>

        <select [formControl]="selectPasportEntitiesPaging" id="select-pasport-entities-paging"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-1.5 mx-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option *ngFor="let option of searchPasportEntitiesPages" [value]="option.value">
            {{ option.label }}
          </option>
        </select>

        <button type="submit" (click)="nextPagePasportEntities()"
          class="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-xs px-2.5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
          <svg class="w-3 h-3 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
            viewBox="0 0 320 512">
            <path
              d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
          </svg>
        </button>
      </div>
      }
    </div>
    @if (mapboxSelectedLayer === 'pasport') {
    <div class="w-full mt-1 flex flex-row">
      <app-filter-pasport-rvo [name]="'list'" />
      <div class="pl-2">
        <button type="button" (click)="toggleDrawer('drawer-pasport-new-device')"
          class="flex items-center justify-center px-4 py-2 font-medium text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
          <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
            viewBox="0 0 448 512">
            <path
              d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z" />
          </svg>&nbsp;&nbsp;Pridať zariadenie
        </button>
      </div>
    </div>
    }
  </form>
  }

  <!-- list componenet -->
  @if (isListVisible === true) {
  <div class="md:hidden h-2 bg-white dark:bg-gray-800 shadow-md rounded-t-lg">&nbsp;</div>
  @if (mapboxSelectedLayer === 'incidents') {
  <app-list (detailClicked)="onDetailClicked($event)" (showOnMapClicked)="onShowOnMapClicked($event)"
    class="md:hidden overflow-auto bg-white dark:bg-gray-800 shadow-md"></app-list>
  }
  @if (mapboxSelectedLayer === 'pasport') {
  <app-list-pasport (detailClicked)="onDetailsPasportClicked($event)"
    class="md:hidden overflow-auto bg-white dark:bg-gray-800 shadow-md"></app-list-pasport>
  }
  <div class="md:hidden h-2 bg-white dark:bg-gray-800 shadow-md rounded-b-lg mb-20">&nbsp;</div>
  }
</div>

<!-- report details -->
<!-- FIXME: ugly, do we still need this? -->
<div class="text-center hidden">
  <button id="testButtonShowDetails"
    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
    type="button" data-drawer-target="drawer-details" data-drawer-show="drawer-details" data-drawer-backdrop="false"
    data-drawer-placement="right" aria-controls="drawer-details">
    Show bottom drawer
  </button>
</div>
<div class="text-center hidden">
  <button id="testButtonShowMaintenance"
    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
    type="button" data-drawer-target="drawer-bottom-maintenance" data-drawer-show="drawer-bottom-maintenance"
    data-drawer-backdrop="false" data-drawer-placement="right" aria-controls="drawer-bottom-maintenance">
    Show bottom drawer
  </button>
</div>
<div class="text-center hidden">
  <button id="testButtonShowQrCodeScanner"
    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
    type="button" data-drawer-target="drawer-bottom-qr-code-scanner" data-drawer-show="drawer-bottom-qr-code-scanner"
    data-drawer-backdrop="false" data-drawer-placement="right" aria-controls="drawer-bottom-qr-code-scanner">
    Show bottom drawer
  </button>
</div>
<div class="text-center hidden">
  <button id="testButtonShowNewReport"
    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
    type="button" data-drawer-target="drawer-new-report-example" data-drawer-show="drawer-new-report-example"
    data-drawer-backdrop="false" aria-controls="drawer-new-report-example" data-drawer-placement="right">
    Show drawer without backdrop
  </button>
</div>

<app-details (maintenanceClicked)="onMaintenanceClicked($event)"
  (qrCodeScannerClicked)="onQrCodeScannerClicked($event)">
</app-details>
<!-- <app-maintenance (qrCodeScannerClicked)="onQrCodeScannerClicked($event)"></app-maintenance> -->
<app-qr-code-scanner (onClickShowPasportDetail)="showPasportDetails($event)"></app-qr-code-scanner>
<div #popupContainer></div>
<app-new-report (qrCodeScannerClicked)="onQrCodeScannerNewReportClicked()"></app-new-report>


<!-- drawer menu -->
<div #drawerMenuRef id="drawer-menu" class="fixed top-0 left-0 z-80 h-screen p-4 pt-4 overflow-y-auto border-r shadow-right-lg transition-transform -translate-x-full bg-gray-50 dark:bg-gray-800
  w-80 md:pt-4 md:pl-20 md:w-96" tabindex="-1" aria-labelledby="drawer-menu-label">
  <h5 id="" class="text-base font-semibold text-gray-500 uppercase dark:text-gray-400">
    Hlavné menu</h5>
  <button type="button" (click)="toggleDrawer('drawer-menu')" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white
    md:top-2.5">
    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
    </svg>
    <span class="sr-only">Close menu</span>
  </button>
  <div class="py-4 px-1 overflow-y-auto">
    <br />

    <div class="flex items-center">
      <div
        class="inline-flex items-center justify-center mx-2.5 w-14 h-14 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
        <span class="font-medium text-gray-600 dark:text-gray-300">ID</span>
      </div>
      <div>
        <h1 class="text-xl font-bold">{{ userDetails?.name }}</h1>
        <h3 class="text-md font-bold text-gray-600">{{ userDetails?.email }}</h3>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-4 mt-8">
      <a href="#"
        class="px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
        Uzamknúť
      </a>
      <a href="#" (click)="logout($event)"
        class="px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
        Odhlásiť
      </a>
    </div>

    <br /><br /><br />

    <div>
      <ul class="space-y-2">
        <!-- <li>
          <a href="#"
            class="flex items-center px-4 py-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
            <div
              class="inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
              <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                viewBox="0 0 512 512">
                <path
                  d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
              </svg>
            </div>
            <span class="ml-3 font-semibold">Poruchy a hlásenia</span>
          </a>
        </li> -->
        <li>
          <a href="#" (click)="$event.preventDefault(); onMainMenuNewReportClicked()"
            class="flex items-center px-4 py-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
            <div
              class="inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
              <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                viewBox="0 0 512 512">
                <path
                  d="M78.6 5C69.1-2.4 55.6-1.5 47 7L7 47c-8.5 8.5-9.4 22-2.1 31.6l80 104c4.5 5.9 11.6 9.4 19 9.4h54.1l109 109c-14.7 29-10 65.4 14.3 89.6l112 112c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-112-112c-24.2-24.2-60.6-29-89.6-14.3l-109-109V104c0-7.5-3.5-14.5-9.4-19L78.6 5zM19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L233.7 374.3c-7.8-20.9-9-43.6-3.6-65.1l-61.7-61.7L19.9 396.1zM512 144c0-10.5-1.1-20.7-3.2-30.5c-2.4-11.2-16.1-14.1-24.2-6l-63.9 63.9c-3 3-7.1 4.7-11.3 4.7H352c-8.8 0-16-7.2-16-16V102.6c0-4.2 1.7-8.3 4.7-11.3l63.9-63.9c8.1-8.1 5.2-21.8-6-24.2C388.7 1.1 378.5 0 368 0C288.5 0 224 64.5 224 144l0 .8 85.3 85.3c36-9.1 75.8 .5 104 28.7L429 274.5c49-23 83-72.8 83-130.5zM56 432a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z" />
              </svg>
            </div>
            <span class="ml-3 font-semibold">Nová porucha</span>
          </a>
        </li>
        <li>
          <a href="#" (click)="$event.preventDefault(); onMainMenuQrCodeScannerClicked()"
            class="flex items-center px-4 py-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
            <div
              class="inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
              <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                viewBox="0 0 448 512">
                <path
                  d="M0 80C0 53.5 21.5 32 48 32l96 0c26.5 0 48 21.5 48 48l0 96c0 26.5-21.5 48-48 48l-96 0c-26.5 0-48-21.5-48-48L0 80zM64 96l0 64 64 0 0-64L64 96zM0 336c0-26.5 21.5-48 48-48l96 0c26.5 0 48 21.5 48 48l0 96c0 26.5-21.5 48-48 48l-96 0c-26.5 0-48-21.5-48-48l0-96zm64 16l0 64 64 0 0-64-64 0zM304 32l96 0c26.5 0 48 21.5 48 48l0 96c0 26.5-21.5 48-48 48l-96 0c-26.5 0-48-21.5-48-48l0-96c0-26.5 21.5-48 48-48zm80 64l-64 0 0 64 64 0 0-64zM256 304c0-8.8 7.2-16 16-16l64 0c8.8 0 16 7.2 16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s7.2-16 16-16s16 7.2 16 16l0 96c0 8.8-7.2 16-16 16l-64 0c-8.8 0-16-7.2-16-16s-7.2-16-16-16s-16 7.2-16 16l0 64c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-160zM368 480a16 16 0 1 1 0-32 16 16 0 1 1 0 32zm64 0a16 16 0 1 1 0-32 16 16 0 1 1 0 32z" />
              </svg>
            </div>
            <span class="ml-3 font-semibold">Skener QR kódu</span>
          </a>
        </li>
        <li>
          <a href="#" (click)="showTestNotification(); $event.preventDefault()"
            class="flex items-center px-4 py-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
            <div
              class="inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
              <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                viewBox="0 0 512 512">
                <path
                  d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h96v80c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64z" />
              </svg>
            </div>
            <span class="ml-3 font-semibold">Notifikácie</span>
          </a>
        </li>
      </ul>

      <!-- User password change -->
      <div class="mt-12 ">
        <h6 class="text-lg font-bold dark:text-white">Zmena hesla</h6>
        <hr class="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700">

        <form action="#">
          <div class="mb-6">
            <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Heslo</label>
            <input [(ngModel)]="settingsPassword" type="password" name="password" id="password"
              placeholder="Vaše aktuálne heslo" value="password"
              class="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required="true">
          </div>
          <div class="mb-2">
            <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nové
              heslo</label>
            <input [(ngModel)]="settingsPasswordNew" type="password" name="password" id="passwordNew"
              placeholder="Vaše nové heslo" value="password"
              [ngClass]="{'bg-red-50': (settingsPasswordNew !== settingsPasswordNewRepeat) }"
              class="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required="true">
          </div>
          <div class="mb-2">
            <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Potvrdenie
              hesla</label>
            <input [(ngModel)]="settingsPasswordNewRepeat" type="password" name="password" id="passwordNewRepeat"
              placeholder="Potvrďte Vaše nové heslo" value="password"
              [ngClass]="{'bg-red-50': (settingsPasswordNew !== settingsPasswordNewRepeat) }"
              class="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required="true">
          </div>
          @if (passwordChangeError) {
          <div
            class="flex items-center my-2 p-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
            role="alert">
            <svg class="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              fill="currentColor" viewBox="0 0 20 20">
              <path
                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <span class="sr-only">Info</span>
            <div>
              <span class="font-medium">Oops!</span> Pri zmene hesla došlo k chybe.
              Prosím, skúste to znova.
            </div>
          </div>
          }
          <div class="flex items-center justify-center mt-2">
            <button (click)="changePassword()" [disabled]="settingsPasswordNew !== settingsPasswordNewRepeat" class="
            flex justify-center items-center
            mt-4 mb-4 w-full px-4 py-2 text-sm font-medium text-center
            text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200
            dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700
            disabled:opacity-50 disabled:cursor-not-allowed">
              @if (isChangingPassword) {
              <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-white animate-spin"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB" />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor" />
              </svg>
              }
              {{ isChangingPassword ? 'Prebieha zmena hesla ...' : 'Zmeniť heslo' }}
            </button>
          </div>
        </form>
      </div>

      <div class="mt-12 ">
        <h6 class="text-lg font-bold dark:text-white">Reset nastavení</h6>
        <hr class="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700">

        <p>
          <small class="text-gray-500 dark:text-gray-400">
            Táto akcia vymaže všetky nastavenia aplikácie a vráti ju do pôvodného stavu.
            Po resetovaní sa budete musieť znova prihlásiť.
            Zrušenie oprávnení pre notifikácie, kameru a mikrofón ako aj prípadné úplné odstránenie nainštalovanej
            aplikácie musíte vykonať manuálne vo vašom operačnom systéme.
          </small>
        </p>
        <div class="flex items-center justify-center">
          <button (click)="factoryReset()"
            class="mt-4 mb-4 w-full px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
            Resetovať nastavenia aplikácie
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Bottom of the page to add some space for bottom navigation -->
  <div>
    <br /><br /><br /><br /><br /><br /><br />
  </div>
</div>
<!-- drawer filter -->
<div #drawerFilterRef id="drawer-filter" class="fixed top-0 left-0 z-80 h-screen p-4 pt-4 overflow-y-auto border-r shadow-right-lg transition-transform -translate-x-full bg-gray-50 dark:bg-gray-800
  w-80 md:pt-4 md:pl-20 md:w-96" tabindex="-1" aria-labelledby="drawer-filter-label">
  <h5 id="drawer-filter-label" class="text-base font-semibold text-gray-500 uppercase dark:text-gray-400">
    Filter vyhľadávania</h5>
  <button type="button" (click)="toggleDrawer('drawer-filter')" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white
    md:top-2.5">
    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
    </svg>
    <span class="sr-only">Close menu</span>
  </button>
  <div class="py-4 overflow-y-auto">
    <app-filter (onFilterChange)="handleFilterChange()" (onFilterWithInitChange)="handleFilterWithInitChange()"></app-filter>
  </div>
  <!-- Bottom of the page to add some space for bottom navigation -->
  <div>
    <br /><br /><br /><br /><br /><br /><br />
  </div>
</div>
<!-- drawer map -->
<div #drawerMapRef id="drawer-map" class="fixed top-0 left-0 z-80 h-screen p-4 pt-4 overflow-y-auto border-r shadow-right-lg transition-transform -translate-x-full bg-gray-50 dark:bg-gray-800
  w-80 md:pt-4 md:pl-20 md:w-96" tabindex="-1" aria-labelledby="drawer-map-label">
  <h5 id="drawer-map-label" class="text-base font-semibold text-gray-500 uppercase dark:text-gray-400">
    Mapa</h5>
  <button type="button" (click)="toggleDrawer('drawer-map')" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white
    md:top-2.5">
    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
    </svg>
    <span class="sr-only">Close menu</span>
  </button>

  <div class="py-4 px-2 overflow-y-auto">
    <!-- <div (click)="mapSettings3DTerrain=!mapSettings3DTerrain">
      {{ mapSettings3DTerrain }}
    </div> -->
    <button type="button" (click)="toggleTerrain()"
      class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white">
      2D / 3D
    </button>

    <form class="max-w-xl mt-2 mb-1" [formGroup]="searchForm" (ngSubmit)="searchAddress()">
      <label for="input-search-address"
        class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
      <div class="relative">
        <input type="search" [formControl]="inputMapSearchAddress" id="input-serach-address"
          class="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Zadajte adresu ..." required>
      </div>

      @if (geocoderResults.length > 0) {
      <div id="searchAddressResults" class="bg-white rounded-lg shadow dark:bg-gray-700 mt-2 p-2 w-full">
        <ul class="text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownSearchButton">
          @for (item of geocoderResults; track item.id) {
          <li (click)="gotoAddress(item)">
            <div class="flex items-center ps-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600 hover:cursor-pointer">
              <label
                class="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300 hover:cursor-pointer">{{
                item.matching_place_name }}</label>
            </div>
          </li>
          }
        </ul>
      </div>
      } @else {
      <div id="searchAddressResults"
        class="bg-white rounded-lg shadow dark:bg-gray-700 mt-2 p-2 w-full text-center text-gray-400">
        <b>Žiadne výsledky</b><br />
        Zadajte adresu napr. v tvare M. R. Štefánika 129 010 01 Žilina a stlačte Enter pre vyhľadanie.
      </div>
      }
    </form>
  </div>
  <!-- Bottom of the page to add some space for bottom navigation -->
  <div>
    <br /><br /><br /><br /><br /><br /><br />
  </div>
</div>
<!-- drawer table component -->
<!-- TODO: modify [class.vw-95]="isGridLayoutFull" for md screen -->
<div #drawerTableRef [class.vw-95.md]="isGridLayoutFull" [ngClass]="{'w-1/2': !isGridLayoutFull}"
  class="hidden md:block md:fixed top-0 right-0 z-30 h-screen p-2 border-l shadow-left-lg overflow-y-auto duration-500 transition-transform translate-x-full  bg-gray-50 dark:bg-gray-800"
  data-drawer-backdrop="false" tabindex="-1" aria-labelledby="drawer-bottom-label">

  <!-- Start coding here -->
  <div class="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
    <div class="flex flex-col md:flex-row items-center justify-between px-4 py-2">
      <div class="w-full flex items-center">
        <div class="py-4 pr-4">
          <button type="button" (click)="toggleLayoutType('wide')"
            class="focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center dark:focus:ring-gray-800
          text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
            <svg class="w-3 h-3" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                d="M128 136c0-22.1-17.9-40-40-40L40 96C17.9 96 0 113.9 0 136l0 48c0 22.1 17.9 40 40 40H88c22.1 0 40-17.9 40-40l0-48zm0 192c0-22.1-17.9-40-40-40H40c-22.1 0-40 17.9-40 40l0 48c0 22.1 17.9 40 40 40H88c22.1 0 40-17.9 40-40V328zm32-192v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V136c0-22.1-17.9-40-40-40l-48 0c-22.1 0-40 17.9-40 40zM288 328c0-22.1-17.9-40-40-40H200c-22.1 0-40 17.9-40 40l0 48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V328zm32-192v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V136c0-22.1-17.9-40-40-40l-48 0c-22.1 0-40 17.9-40 40zM448 328c0-22.1-17.9-40-40-40H360c-22.1 0-40 17.9-40 40v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V328z" />
            </svg>
            <span class="sr-only">Wide layout</span>
          </button>
          &nbsp;
          <button type="button" (click)="toggleLayoutType('slim')"
            class="focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center dark:focus:ring-gray-800
            text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
            <svg class="w-3 h-3" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path
                d="M40 352l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zm192 0l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 320c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 192l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 160c-22.1 0-40-17.9-40-40L0 72C0 49.9 17.9 32 40 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40z" />
            </svg>
            <span class="sr-only">Slim layout</span>
          </button>
          <!-- Celkom: {{ incidentsCount }} -->
        </div>
        @if (this.mapboxSelectedLayer === 'pasport') {
        <div class="pr-4">
          <app-filter-pasport-rvo [name]="'table'" />
        </div>
        }
        <form class="flex-1" [formGroup]="searchForm">
          <label for="input-search-fulltext"
            class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
          <div class="relative">
            <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
              </svg>
            </div>
            <input type="search" [formControl]="inputMapFilterFulltext" id="input-search-fulltext"
              class="block w-full px-4 py-3 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Vyhľadávanie ...">
          </div>
        </form>

        @if (this.mapboxSelectedLayer === 'incidents') {
        <div>
          <nav class="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
            aria-label="Table navigation">
            <ul class="inline-flex items-stretch space-x-1">
              <li>
                <a (click)="prevPage(); $event.preventDefault()" href="#"
                  class="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                  <span class="sr-only">Previous</span>
                  <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                </a>
              </li>
              <li>
                <select [formControl]="selectIncidentsPaging" id="select-incidents-paging"
                  class="flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                  <option *ngFor="let option of searchIncidentsPages" [value]="option.value">
                    {{ option.label }}/{{ searchIncidentsPages.length }}&nbsp;&nbsp;&nbsp;{{ option.start }}-{{
                    option.end }}/{{ incidentsCount }}
                  </option>
                </select>
              </li>
              <li>
                <a (click)="nextPage(); $event.preventDefault()" href="#"
                  class="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                  <span class="sr-only">Next</span>
                  <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        }

        @if (this.mapboxSelectedLayer === 'pasport') {
        <div>
          <nav class="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
            aria-label="Table navigation">
            <ul class="inline-flex items-stretch space-x-1">
              <li>
                <a (click)="prevPagePasportEntities(); $event.preventDefault()" href="#"
                  class="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                  <span class="sr-only">Previous</span>
                  <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                </a>
              </li>
              <li>
                <select [formControl]="selectPasportEntitiesPaging" id="select-pasport-entities-paging"
                  class="flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                  <option *ngFor="let option of searchPasportEntitiesPages" [value]="option.value">
                    {{ option.label }}/{{ searchPasportEntitiesPages.length }}&nbsp;&nbsp;&nbsp;{{ option.start }}-{{
                    option.end }}/{{ pasportEntitiesCount }}
                  </option>
                </select>
              </li>
              <li>
                <a (click)="nextPagePasportEntities(); $event.preventDefault()" href="#"
                  class="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                  <span class="sr-only">Next</span>
                  <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd" />
                  </svg>
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <div class="pr-1">
          <button type="button" (click)="exportPasportCsv()"
            class="w-full lg:w-auto flex items-center justify-center px-4 py-2 font-medium text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
            &nbsp;
            <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 512 512">
              <path
                d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM200 352l16 0c22.1 0 40 17.9 40 40l0 8c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-8c0-4.4-3.6-8-8-8l-16 0c-4.4 0-8 3.6-8 8l0 80c0 4.4 3.6 8 8 8l16 0c4.4 0 8-3.6 8-8l0-8c0-8.8 7.2-16 16-16s16 7.2 16 16l0 8c0 22.1-17.9 40-40 40l-16 0c-22.1 0-40-17.9-40-40l0-80c0-22.1 17.9-40 40-40zm133.1 0l34.9 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-34.9 0c-7.2 0-13.1 5.9-13.1 13.1c0 5.2 3 9.9 7.8 12l37.4 16.6c16.3 7.2 26.8 23.4 26.8 41.2c0 24.9-20.2 45.1-45.1 45.1L304 512c-8.8 0-16-7.2-16-16s7.2-16 16-16l42.9 0c7.2 0 13.1-5.9 13.1-13.1c0-5.2-3-9.9-7.8-12l-37.4-16.6c-16.3-7.2-26.8-23.4-26.8-41.2c0-24.9 20.2-45.1 45.1-45.1zm98.9 0c8.8 0 16 7.2 16 16l0 31.6c0 23 5.5 45.6 16 66c10.5-20.3 16-42.9 16-66l0-31.6c0-8.8 7.2-16 16-16s16 7.2 16 16l0 31.6c0 34.7-10.3 68.7-29.6 97.6l-5.1 7.7c-3 4.5-8 7.1-13.3 7.1s-10.3-2.7-13.3-7.1l-5.1-7.7c-19.3-28.9-29.6-62.9-29.6-97.6l0-31.6c0-8.8 7.2-16 16-16z" />
            </svg>
            &nbsp;
          </button>
        </div>
        <div class="pr-4">
          <button type="button" (click)="exportPasportPdf()"
            class="w-full lg:w-auto flex items-center justify-center px-4 py-2 font-medium text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
            &nbsp;
            <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 512 512">
              <path
                d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z" />
            </svg>
            &nbsp;
          </button>
        </div>
        }

        <div class="pr-4">
          <button type="button" (click)="toggleDrawer('drawer-pasport-new-device')"
            class="w-full lg:w-auto flex items-center justify-center px-4 py-2 font-medium text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
            <svg class="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
              viewBox="0 0 448 512">
              <path
                d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z" />
            </svg>&nbsp;&nbsp;Pridať zariadenie
          </button>
        </div>

        <div>
          <button type="button" (click)="toggleDrawer('drawer-table')"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white">
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
            </svg>
            <span class="sr-only">Close menu</span>
          </button>
        </div>
      </div>
      <div
        class="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">

      </div>
    </div>

    @if (this.mapboxSelectedLayer === 'incidents') {
    <app-table (detailClicked)="onDetailClicked($event)" (showOnMapClicked)="onShowOnMapClicked($event)"
      class="hidden md:block overflow-auto bg-white dark:bg-gray-800 shadow-md"></app-table>
    } @else if (this.mapboxSelectedLayer === 'pasport') {
    <app-table-pasport (detailClicked)="onDetailsPasportClicked($event)"
      class="hidden md:block overflow-auto bg-white dark:bg-gray-800 shadow-md"></app-table-pasport>
    }

  </div>

</div>

<!-- drawer messages component -->
<div #drawerMessagesRef class="fixed top-0 right-0 z-30 h-screen p-4 border-l shadow-left-lg overflow-y-auto transition-transform translate-x-full bg-white dark:bg-gray-800
  w-full lg:w-96" data-drawer-backdrop="false" tabindex="-1" aria-labelledby="drawer-bottom-label">

  <h5 id="drawer-right-label"
    class="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400">
    <svg class="w-4 h-4 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
      viewBox="0 0 512 512">
      <path
        d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h96v80c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64z" />
    </svg>
    Správy a notifikácie
  </h5>


  <!-- TODO: change to function close -->
  <button type="button" (click)="toggleDrawer('drawer-messages')" data-drawer-hide="drawer-messages"
    aria-controls="drawer-messages"
    class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white">
    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
    </svg>
    <span class="sr-only">Close menu</span>
  </button>

  <app-messages (detailClicked)="onDetailClicked($event)" (scrollToClicked)="onScrollToClicked($event)"></app-messages>
</div>

<!-- drawer pasport details -->
<div #drawerPasportDetailsRef class="fixed top-0 right-0 z-30 h-screen p-4 border-l shadow-left-lg overflow-y-auto transition-transform translate-x-full bg-white dark:bg-gray-800
  w-full lg:w-1/3" data-drawer-backdrop="false" tabindex="-1" aria-labelledby="drawer-bottom-label">

  <h5 id="drawer-right-label"
    class="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400">
    <svg class="w-4 h-4 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
      viewBox="0 0 512 512">
      <path
        d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h96v80c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64z" />
    </svg>
    Detaily
  </h5>

  <!-- TODO: change to function close -->
  <button type="button" (click)="toggleDrawer('drawer-pasport-details')" data-drawer-hide="drawer-pasport-details"
    aria-controls="drawer-pasport-details"
    class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white">
    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
    </svg>
    <span class="sr-only">Close menu</span>
  </button>

  <app-pasport-details (onClickNewIncident)="showNewIncidentForm($event)"></app-pasport-details>
</div>

<!-- drawer dashboard component -->
<div #drawerDashboardRef class="fixed top-0 right-0 z-30 h-screen border-l shadow-left-lg overflow-y-auto transition-transform translate-x-full bg-gray-50 dark:bg-gray-800
  w-full md:w-5/6" data-drawer-backdrop="false" tabindex="-1" aria-labelledby="drawer-dashboard-label">

  <div class="flex flex-col md:flex-row items-center justify-between px-4 py-2">
    <div class="w-full flex items-center">
      <div class="py-4 pr-4">
        <button type="button" (click)="refreshDashboard()"
          class="inline-flex items-center text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
          <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512"
            class="w-3.5 h-3.5">
            <path
              d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z">
            </path>
          </svg>&nbsp;Aktualizovať</button>
      </div>

      <div class="flex-1 pr-12">
        <h1 class="text-xl font-bold text-center">
          Reporty a štatistiky
        </h1>
      </div>

      <div>
        <button type="button" (click)="toggleDrawer('drawer-dashboard')"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white">
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
          <span class="sr-only">Close dashboard</span>
        </button>
      </div>
    </div>
    <div
      class="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">

    </div>
  </div>

  <app-dashboard #dashboardComponent></app-dashboard>
  <app-dashboard-full #dashboardFullComponent></app-dashboard-full>

  <!-- Bottom of the page to add some space for bottom navigation -->
  <div>
    <br /><br /><br /><br /><br /><br /><br />
  </div>

</div>

<!-- drawer pasport details -->
<div #drawerPasportNewDeviceRef class="fixed top-0 right-0 z-30 h-screen p-4 border-l shadow-left-lg overflow-y-auto transition-transform translate-x-full bg-white dark:bg-gray-800
  w-full lg:w-1/3" data-drawer-backdrop="false" tabindex="-1" aria-labelledby="drawer-bottom-label">

  <h5 id="drawer-right-label"
    class="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400">
    <svg class="w-4 h-4 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
      viewBox="0 0 512 512">
      <path
        d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h96v80c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64z" />
    </svg>
    Nové zariadenie
  </h5>

  <!-- TODO: change to function close -->
  <button type="button" (click)="toggleDrawer('drawer-pasport-new-device')" data-drawer-hide="drawer-pasport-new-device"
    aria-controls="drawer-pasport-new-device"
    class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white">
    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
    </svg>
    <span class="sr-only">Close menu</span>
  </button>

  <app-pasport-new-device #pasportNewDeviceComponent></app-pasport-new-device>
</div>
