import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-signin',
  standalone: true,
  imports: [CommonModule, RouterOutlet, FormsModule],
  templateUrl: './signin.component.html',
  styleUrl: './signin.component.css'
})
export class SigninComponent {
  // username: string = 'osvetlenie@tsmza.sk';
  // password: string = 'heslo';
  username: string = '';
  password: string = '';
  remember: boolean = true;

  isPending: boolean = false;
  loginError: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  // TODO: change this. ngmodel not working. could be out of sync
  async changeRemember() {
    this.remember = !this.remember;
  }

  async login() {
    this.isPending = true;
    this.loginError = false;

    // Artificial delay of 1 second
    await new Promise(resolve => setTimeout(resolve, 1000));

    const success = await this.authService.login(this.username, this.password, this.remember);
    this.isPending = false;

    if (success) {
      this.router.navigate(['/incidents']); // Navigate to home or dashboard page
    } else {
      // Handle login failure (e.g., show an error message)
      this.loginError = true;
    }
  }

}
