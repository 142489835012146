import { Component, EventEmitter, Output } from '@angular/core';
import { DataService, PasportEntity } from '@app/services/data.service';
import { CommonModule } from '@angular/common';
import { FormatPasportEntityAttributesPipe } from '@app/shared/format-pasport-entity-attributes.pipe';

@Component({
  selector: 'app-list-pasport',
  standalone: true,
  imports: [CommonModule, FormatPasportEntityAttributesPipe],
  templateUrl: './list-pasport.component.html',
  styleUrl: './list-pasport.component.css'
})
export class ListPasportComponent {
  data: PasportEntity[] = [];

  constructor(public dataService: DataService) { }

  ngOnInit() {
    this.dataService.getPasportEntitiesFilteredPaged().subscribe((data: PasportEntity[]) => {
      this.data = data;
    });
  }

  @Output() detailClicked: EventEmitter<PasportEntity> = new EventEmitter();
  @Output() showOnMapClicked: EventEmitter<PasportEntity> = new EventEmitter();
  showDetails(pasportEntity: PasportEntity) {
    this.detailClicked.emit(pasportEntity);
  }
  showIncidentOnMap(pasportEntity: PasportEntity) {
    this.showOnMapClicked.emit(pasportEntity);
  }

}
