import { Pipe, PipeTransform } from '@angular/core';
import { StateManagementService } from '../services/state-management.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'formatPasportEntityAttributes',
  standalone: true
})
export class FormatPasportEntityAttributesPipe implements PipeTransform {

  constructor(
    private stateManagementService: StateManagementService,
    private sanitizer: DomSanitizer
  ) { }

  transform(value: any): SafeHtml {
    if (!value) return ''; // If no data, return empty string

    // List of keys to exclude
    const excludeKeys = ['name', 'entityType', 'importUUID', 'uuid', 'PasportType'];
    let mainResult: string[] = [];
    let locationBlock: string[] = [];

    // Iterate over the object and build the formatted string
    for (const key in value) {
      if (value.hasOwnProperty(key) && !excludeKeys.includes(key)) {
        let translatedKey = this.stateManagementService.getPasportTemplateDictionary()[key] || key;
        let formattedKey = translatedKey.charAt(0).toUpperCase() + translatedKey.slice(1);
        let formattedValue = `<span class="font-bold">${formattedKey}:</span> ${value[key]}`;

        if (['address', 'latitude', 'longitude'].includes(key)) {
          if (key === 'latitude') translatedKey = 'Lat';
          if (key === 'longitude') translatedKey = 'Lng';
          formattedKey = translatedKey.charAt(0).toUpperCase() + translatedKey.slice(1);
          formattedValue = `<span class="font-bold">${formattedKey}:</span> ${value[key]}`;
          locationBlock.push(formattedValue);
        } else {
          mainResult.push(formattedValue);
        }
      }
    }

    // Create the location block
    let locationHtml = '';
    if (locationBlock.length > 0) {
      locationHtml = `
        <span class="location-block">
          ${locationBlock[0]} ${locationBlock.slice(1).join(' ')}
        </span>
      `;
    }

    // Join the main result
    const mainHtml = mainResult.join(', ');

    // Combine location block and main result
    const htmlString = locationHtml + mainHtml;

    // Sanitize the HTML string to prevent XSS attacks
    return this.sanitizer.bypassSecurityTrustHtml(htmlString);
  }
}