<div class="overflow-auto">
  <ul class="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownHelperFilterButton">
    <li>
      <label for="mapFilterSelectOrder" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Zoradenie
        záznamov</label>
      <!-- TODO: generate dynamically from constant   -->
      <select id="mapFilterSelectOrder" [formControl]="mapFilterSelectOrder"
        class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option value="evidenceDate_desc" selected>Najnovšie podľa dátumu vytvorenia</option>
        <option value="evidenceDate_asc">Najstaršie podľa dátumu vytvorenia</option>
        <option value="lastUpdateDate_desc">Od najnovšie upravených</option>
        <option value="lastUpdateDate_asc">Od najstaršie upravených</option>
        <option value="name_asc">Názov A -> Z</option>
        <option value="name_desc">Názov Z -> A</option>
        <option value="state_asc">Status A -> Z</option>
        <option value="state_desc">Status Z -> A</option>
      </select>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="helper-checkbox-0" aria-describedby="helper-checkbox-text-0" type="checkbox" value=""
            [formControl]="mapFilterCheckboxLast24h"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="helper-checkbox-0" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Iba za posledný 1 deň</div>
            <p id="helper-checkbox-text-0" class="text-xs font-normal text-gray-500 dark:text-gray-300">
              Poruchy, ktoré boli vytvorené za posledných 24 hodín
            </p>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="map-filter-checkbox-high-importance" aria-describedby="map-filter-checkbox-high-importance-text" type="checkbox" value=""
            [formControl]="mapFilterCheckboxHighImportance"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="map-filter-checkbox-high-importance" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Iba prioritné</div>
            <p id="map-filter-checkbox-high-importance-text" class="text-xs font-normal text-gray-500 dark:text-gray-300">
              Poruchy, ktoré majú nastavenú vysokú prioritu
            </p>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="helper-checkbox-status-new" aria-describedby="helper-checkbox-status-new" type="checkbox" value=""
            [formControl]="mapFilterCheckboxStatusNew"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="helper-checkbox-status-new" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Nové poruchy</div>
            <p id="helper-checkbox-status-new" class="text-xs font-normal text-gray-500 dark:text-gray-300">
              Úplne nové, bez manipulácie či preverovania
            </p>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="helper-checkbox-status-inprogress" aria-describedby="helper-checkbox-status-inprogress"
            type="checkbox" value="" [formControl]="mapFilterCheckboxStatusInprogress"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="helper-checkbox-status-inprogress" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Aktívne poruchy</div>
            <p id="helper-checkbox-status-inprogress" class="text-xs font-normal text-gray-500 dark:text-gray-300">
              V aktívnom procese riešenia v krátkodobom horizonte
            </p>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="helper-checkbox-status-pending" aria-describedby="helper-checkbox-status-pending" type="checkbox"
            value="" [formControl]="mapFilterCheckboxStatusPending"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="helper-checkbox-status-pending" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Dočasne pozastavené</div>
            <p id="helper-checkbox-status-pending" class="text-xs font-normal text-gray-500 dark:text-gray-300">
              Riešenie je naplánované na neskôr alebo čaká na ďalšie udalosti
            </p>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="helper-checkbox-status-solved" aria-describedby="helper-checkbox-status-solved" type="checkbox"
            value="" [formControl]="mapFilterCheckboxStatusSolved"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="helper-checkbox-status-solved" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Vyriešené poruchy</div>
            <p id="helper-checkbox-status-solved" class="text-xs font-normal text-gray-500 dark:text-gray-300">
              Úspešne vyriešené odstránením poruchy či iného nedostatku
            </p>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="helper-checkbox-status-unresolved" aria-describedby="helper-checkbox-status-unresolved"
            type="checkbox" value="" [formControl]="mapFilterCheckboxStatusUnresolved"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="helper-checkbox-status-unresolved" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Uzatvorené bez riešenia</div>
            <p id="helper-checkbox-status-unresolved" class="text-xs font-normal text-gray-500 dark:text-gray-300">
              Bez predpokladu na odstránenie závady či iného nedostatku
            </p>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="helper-checkbox-status-terminated" aria-describedby="helper-checkbox-status-terminated"
            type="checkbox" value="" [formControl]="mapFilterCheckboxStatusTerminated"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="helper-checkbox-status-terminated" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Zrušené</div>
            <p id="helper-checkbox-status-terminated" class="text-xs font-normal text-gray-500 dark:text-gray-300">
              Trvalo odstránené, zrušené bez možnosti budúcich úrav
            </p>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="helper-checkbox-3" aria-describedby="helper-checkbox-text-3" type="checkbox" value=""
            [formControl]="mapFilterCheckboxSourceDvo"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="helper-checkbox-3" class="font-medium text-gray-900 dark:text-gray-300">
            <div>LightNet / DVO</div>
            <p id="helper-checkbox-text-3" class="text-xs font-normal text-gray-500 dark:text-gray-300">
              Poruchy generované automaticky systémom LightNet / DVO
            </p>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="helper-checkbox-4" aria-describedby="helper-checkbox-text-4" type="checkbox" value=""
            [formControl]="mapFilterCheckboxsourcePublic"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="helper-checkbox-4" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Verejné</div>
            <p id="helper-checkbox-text-4" class="text-xs font-normal text-gray-500 dark:text-gray-300">
              Poruchy vytvorené verejnosťou
            </p>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="helper-checkbox-5" aria-describedby="helper-checkbox-text-5" type="checkbox" value=""
            [formControl]="mapFilterCheckboxsourceOperator"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="helper-checkbox-5" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Operátor</div>
            <p id="helper-checkbox-text-5" class="text-xs font-normal text-gray-500 dark:text-gray-300">
              Poruchy vytvorené operátorom manuálne
            </p>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="helper-checkbox-6" aria-describedby="helper-checkbox-text-6" type="checkbox" value=""
            [formControl]="mapFilterCheckboxMyIncidentsAndTasks"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="helper-checkbox-6" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Iba moje poruchy a hlásenia</div>
            <p id="helper-checkbox-text-6" class="text-xs font-normal text-gray-500 dark:text-gray-300">
              Zobrazovať iba poruchy a hlásenia, ktoré sú pridelené na aktuálne prihláseného používateľa
            </p>
          </label>
        </div>
      </div>
    </li>

    <li>
      <div class="font-bold mt-8 mb-2">Kategórie porúch</div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="map-filter-checkbox-power-supply-failure" type="checkbox" value=""
            [formControl]="mapFilterCheckboxPowerSupplyFailure"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="map-filter-checkbox-power-supply-failure" class="font-medium text-gray-900 dark:text-gray-300">
            <div>{{ nameMapping['POWER_SUPPLY_FAILURE'] }}</div>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="map-filter-checkbox-lighting-failure" type="checkbox" value=""
            [formControl]="mapFilterCheckboxLightingFailure"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="map-filter-checkbox-lighting-failure" class="font-medium text-gray-900 dark:text-gray-300">
            <div>{{ nameMapping['LIGHTING_FAILURE'] }}</div>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="map-filter-checkbox-dimming-failure" type="checkbox" value=""
            [formControl]="mapFilterCheckboxDimmingFailure"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="map-filter-checkbox-dimming-failure" class="font-medium text-gray-900 dark:text-gray-300">
            <div>{{ nameMapping['DIMMING_FAILURE'] }}</div>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="map-filter-checkbox-communication-failure" type="checkbox" value=""
            [formControl]="mapFilterCheckboxCommunicationFailure"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="map-filter-checkbox-communication-failure" class="font-medium text-gray-900 dark:text-gray-300">
            <div>{{ nameMapping['COMMUNICATION_FAILURE'] }}</div>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="map-filter-checkbox-device-failure" type="checkbox" value=""
            [formControl]="mapFilterCheckboxDeviceFailure"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="map-filter-checkbox-device-failure" class="font-medium text-gray-900 dark:text-gray-300">
            <div>{{ nameMapping['DEVICE_FAILURE'] }}</div>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="map-filter-checkbox-configuration-failure" type="checkbox" value=""
            [formControl]="mapFilterCheckboxConfigurationFailure"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="map-filter-checkbox-configuration-failure" class="font-medium text-gray-900 dark:text-gray-300">
            <div>{{ nameMapping['CONFIGURATION_FAILURE'] }}</div>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="map-filter-checkbox-operational-issue" type="checkbox" value=""
            [formControl]="mapFilterCheckboxOperationalIssue"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="map-filter-checkbox-operational-issue" class="font-medium text-gray-900 dark:text-gray-300">
            <div>{{ nameMapping['OPERATIONAL_ISSUE'] }}</div>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="map-filter-checkbox-appearance-issue" type="checkbox" value=""
            [formControl]="mapFilterCheckboxAppearanceIssue"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="map-filter-checkbox-appearance-issue" class="font-medium text-gray-900 dark:text-gray-300">
            <div>{{ nameMapping['APPEARENCE_ISSUE'] }}</div>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="map-filter-checkbox-other-issue" type="checkbox" value=""
            [formControl]="mapFilterCheckboxOtherIssue"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="map-filter-checkbox-other-issue" class="font-medium text-gray-900 dark:text-gray-300">
            <div>{{ nameMapping['OTHER_ISSUE'] }}</div>
          </label>
        </div>
      </div>
    </li>

    <li>
      <div class="font-bold mt-8 mb-2">Typy zariadení</div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="map-filter-checkbox-devicetype-location" aria-describedby="map-filter-checkbox-devicetype-location-text" type="checkbox" value=""
            [formControl]="mapFilterCheckboxDevicetypeLocation"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="map-filter-checkbox-devicetype-location" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Lokalita</div>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="map-filter-checkbox-devicetype-rvo" aria-describedby="map-filter-checkbox-devicetype-rvo-text"
            type="checkbox" value="" [formControl]="mapFilterCheckboxDevicetypeRvo"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="map-filter-checkbox-devicetype-rvo" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Rozvádzač</div>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="map-filter-checkbox-devicetype-phase" aria-describedby="map-filter-checkbox-devicetype-phase-text" type="checkbox" value=""
            [formControl]="mapFilterCheckboxDevicetypePhase"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="map-filter-checkbox-devicetype-phase" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Fáza</div>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="map-filter-checkbox-devicetype-lightline" aria-describedby="map-filter-checkbox-devicetype-lightline-text"
            type="checkbox" value="" [formControl]="mapFilterCheckboxDevicetypeLightline"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="map-filter-checkbox-devicetype-lightline" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Vetva</div>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="map-filter-checkbox-devicetype-lightplace" aria-describedby="map-filter-checkbox-devicetype-lightplace-text"
            type="checkbox" value="" [formControl]="mapFilterCheckboxDevicetypeLightplace"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="map-filter-checkbox-devicetype-lightplace" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Svetelné miesto</div>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="map-filter-checkbox-devicetype-lightpoint" aria-describedby="map-filter-checkbox-devicetype-lightpoint-text"
            type="checkbox" value="" [formControl]="mapFilterCheckboxDevicetypeLightpoint"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="map-filter-checkbox-devicetype-lightpoint" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Svetelný bod</div>
          </label>
        </div>
      </div>
    </li>


    <li>
      <div class="font-bold mt-16 mb-2">Pasport zobrazenie</div>
    </li>

    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="map-filter-checkbox-location" aria-describedby="map-filter-checkbox-location-text" type="checkbox" value=""
            [formControl]="mapFilterCheckboxLocation"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="map-filter-checkbox-location" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Lokalita</div>
            <p id="map-filter-checkbox-location-text" class="text-xs font-normal text-gray-500 dark:text-gray-300">
              Oblasti. Napr. Žilina, Solinky
            </p>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="map-filter-checkbox-rvo" aria-describedby="map-filter-checkbox-rvo-text"
            type="checkbox" value="" [formControl]="mapFilterCheckboxRvo"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="map-filter-checkbox-rvo" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Rozvádzač</div>
            <p id="map-filter-checkbox-rvo-text" class="text-xs font-normal text-gray-500 dark:text-gray-300">
              Zariadenie typu RVO.
            </p>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="map-filter-checkbox-phase" aria-describedby="map-filter-checkbox-phase-text" type="checkbox" value=""
            [formControl]="mapFilterCheckboxPhase"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="map-filter-checkbox-phase" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Fáza</div>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="map-filter-checkbox-lightline" aria-describedby="map-filter-checkbox-lightline-text"
            type="checkbox" value="" [formControl]="mapFilterCheckboxLightline"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="map-filter-checkbox-lightline" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Vetva</div>
            <p id="map-filter-checkbox-lightline-text" class="text-xs font-normal text-gray-500 dark:text-gray-300">
              Lightline
            </p>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="map-filter-checkbox-lightplace" aria-describedby="map-filter-checkbox-lightplace-text"
            type="checkbox" value="" [formControl]="mapFilterCheckboxLightplace"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="map-filter-checkbox-lightplace" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Svetelné miesto</div>
            <p id="map-filter-checkbox-lightplace-text" class="text-xs font-normal text-gray-500 dark:text-gray-300">
              Lightplace. Napr. stĺp, stĺp s kamerou.
            </p>
          </label>
        </div>
      </div>
    </li>
    <li>
      <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
        <div class="flex items-center h-5">
          <input id="map-filter-checkbox-lightpoint" aria-describedby="map-filter-checkbox-lightpoint-text"
            type="checkbox" value="" [formControl]="mapFilterCheckboxLightpoint"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
        </div>
        <div class="ms-2 text-sm">
          <label for="map-filter-checkbox-lightpoint" class="font-medium text-gray-900 dark:text-gray-300">
            <div>Svetelný bod</div>
            <p id="map-filter-checkbox-lightpoint-text" class="text-xs font-normal text-gray-500 dark:text-gray-300">
              Lightpoint. Napr. LED svetlo, LED panel.
            </p>
          </label>
        </div>
      </div>
    </li>
  </ul>
</div>