import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// interface for map filter checkbox states. we can add more components here
// TODO: check if we want to move this interface to a separate file
export interface MapFilterCheckboxStates {
  last24h: boolean;
  highImportance: boolean;
  activeReports: boolean;
  completedReports: boolean;
  sourceDvo: boolean;
  sourcePublic: boolean;
  sourceOperator: boolean;
  myIncidentsAndTasks: boolean;
  statusNew: boolean;
  statusInprogress: boolean;
  statusPending: boolean;
  statusSolved: boolean;
  statusUnresolved: boolean;
  statusTerminated: boolean;
  location: boolean;
  rvo: boolean;
  phase: boolean;
  lightline: boolean;
  lightplace: boolean;
  lightpoint: boolean;
  powerSupplyFailure: boolean;
  lightingFailure: boolean;
  dimmingFailure: boolean;
  communicationFailure: boolean;
  deviceFailure: boolean;
  configurationFailure: boolean;
  operationalIssue: boolean;
  appearanceIssue: boolean;
  otherIssue: boolean;
  devicetypeLocation: boolean;
  devicetypeRvo: boolean;
  devicetypePhase: boolean;
  devicetypeLightline: boolean;
  devicetypeLightplace: boolean;
  devicetypeLightpoint: boolean;
}

export interface MapFilterOrderState {
  orderField: 'name' | 'evidenceDate' | 'lastUpdateDate' | 'state';
  orderDirection: 'asc' | 'desc';
}

export interface MapSelectedPasportItem {
  uuid: string;
  type: string;
}

@Injectable({
  providedIn: 'root'
})
export class StateManagementService {
  private user: BehaviorSubject<string> = new BehaviorSubject<string>('osvetlenie@tsmza.sk');
  private mapFilterCheckboxStates: BehaviorSubject<MapFilterCheckboxStates>;
  private mapFilterOrderState: BehaviorSubject<MapFilterOrderState>;
  private mapFilterFulltext: BehaviorSubject<string> = new BehaviorSubject<string>('');
  // TODO: should this be part of data.service instead of state management service?
  private mapFilterIncidentsPaging: BehaviorSubject<string> = new BehaviorSubject<string>('');
  // TODO: hardcoded value for testing
  private settingsRecordsPerPage: BehaviorSubject<number> = new BehaviorSubject<number>(25);
  // settings for mapbox 3D terrain
  private mapSettings3DTerrain: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private pasportFilterRvos: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  public getDefaultState(): MapFilterCheckboxStates {
    return {
      last24h: false,
      highImportance: false,
      activeReports: true,
      completedReports: true,
      sourceDvo: true,
      sourcePublic: true,
      sourceOperator: true,
      myIncidentsAndTasks: false,
      statusNew: true,
      statusInprogress: true,
      statusPending: true,
      statusSolved: true,
      statusUnresolved: true,
      statusTerminated: true,
      location: true,
      rvo: true,
      phase: true,
      lightline: true,
      lightplace: true,
      lightpoint: true,
      powerSupplyFailure: true,
      lightingFailure: true,
      dimmingFailure: true,
      communicationFailure: true,
      deviceFailure: true,
      configurationFailure: true,
      operationalIssue: true,
      appearanceIssue: true,
      otherIssue: true,
      devicetypeLocation: true,
      devicetypeRvo: true,
      devicetypePhase: true,
      devicetypeLightline: true,
      devicetypeLightplace: true,
      devicetypeLightpoint: true
    };
  }
  public getDefaultOrderState(): MapFilterOrderState {
    return {
      orderField: 'evidenceDate',
      orderDirection: 'asc'
    };
  }

  constructor() {
    const initialState = this.loadInitialStateFromLocalstorage();
    this.mapFilterCheckboxStates = new BehaviorSubject<MapFilterCheckboxStates>(initialState);
    const initialOrderState = this.loadInitialStateOrderFromLocalstorage();
    this.mapFilterOrderState = new BehaviorSubject<MapFilterOrderState>(initialOrderState);
    const initialMapboxSettings = localStorage.getItem('mapboxSettings');
    this.setMapSettings3DTerrain(initialMapboxSettings ? JSON.parse(initialMapboxSettings).mapSettings3DTerrain : false);
    // TODO: should we get initial value for page from localstorage?
  }

  getUser() {
    return this.user.asObservable();
  }
  getMapFilterCheckboxStates() {
    return this.mapFilterCheckboxStates.asObservable();
  }
  getMapFilterOrderState() {
    return this.mapFilterOrderState.asObservable();
  }
  getMapFilterFulltextState() {
    return this.mapFilterFulltext.asObservable();
  }
  getMapFilterIncidentsPagingState() {
    return this.mapFilterIncidentsPaging.asObservable();
  }
  getSettingsRecordsPerPage() {
    return this.settingsRecordsPerPage.asObservable();
  }
  getMapSettings3DTerrain() {
    return this.mapSettings3DTerrain.asObservable();
  }
  getPasportFilterRvos() {
    return this.pasportFilterRvos.asObservable();
  }

  setUser(value: string) {
    this.user.next(value);
  }
  setMapFilterCheckboxState(key: keyof MapFilterCheckboxStates, value: boolean) {
    const currentStates = this.mapFilterCheckboxStates.getValue();
    currentStates[key] = value;
    this.mapFilterCheckboxStates.next(currentStates);
    this.saveStateToLocalstorage(currentStates);
  }
  setMapFilterOrderState(orderField: 'name' | 'evidenceDate' | 'lastUpdateDate' | 'state', orderDirection: 'asc' | 'desc') {
    const currentOrderState = this.mapFilterOrderState.getValue();
    currentOrderState.orderField = orderField;
    currentOrderState.orderDirection = orderDirection;
    this.mapFilterOrderState.next(currentOrderState);
    this.saveOrderStateToLocalstorage(currentOrderState);
  }
  setMapFilterFulltextState(value: string) {
    this.mapFilterFulltext.next(value);
  }
  setMapFilterIncidentsPagingState(value: string) {
    this.mapFilterIncidentsPaging.next(value);
  }
  setSettingsRecordsPerPage(value: number) {
    this.settingsRecordsPerPage.next(value);
  }
  setMapSettings3DTerrain(value: boolean) {
    this.mapSettings3DTerrain.next(value);
    this.saveMapboxSettingsToLocalstorage();
  }
  setPasportFilterRvos(value: string[]) {
    this.pasportFilterRvos.next(value);
  }

  private saveStateToLocalstorage(state: MapFilterCheckboxStates): void {
    localStorage.setItem('mapFilterCheckboxStates', JSON.stringify(state));
  }
  private saveOrderStateToLocalstorage(state: MapFilterOrderState): void {
    localStorage.setItem('mapFilterOrderState', JSON.stringify(state));
  }
  private saveMapboxSettingsToLocalstorage(): void {
    localStorage.setItem('mapboxSettings', JSON.stringify(
      {
        mapSettings3DTerrain: this.mapSettings3DTerrain.getValue()
      }
    ));
  }

  private loadInitialStateFromLocalstorage(): MapFilterCheckboxStates {
    const savedState = localStorage.getItem('mapFilterCheckboxStates');
    const defaultState = this.getDefaultState();
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      return { ...defaultState, ...parsedState };
    } else {
      return defaultState;
    }
  }
  private loadInitialStateOrderFromLocalstorage(): MapFilterOrderState {
    const savedState = localStorage.getItem('mapFilterOrderState');
    return savedState ? JSON.parse(savedState) : this.getDefaultOrderState();
  }

}
