import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ViewChild, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Form, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { DataService, Task, Incident, PasportItem, PasportEntity } from '@services/data.service';
import { FilterComponent } from './filter/filter.component';
import { ListComponent } from './list/list.component';
import { TableComponent } from '@app/features/incidents/table/table.component';
import { TablePasportComponent } from '@app/features/incidents/table-pasport/table-pasport.component';
import { DetailsComponent } from './details/details.component';
import { PasportDetailsComponent } from './pasport-details/pasport-details.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { QrCodeScannerComponent } from './qr-code-scanner/qr-code-scanner.component';
import { initFlowbite } from 'flowbite';
import { StateManagementService } from '@app/services/state-management.service';
import { MapboxPopupComponent } from '@app/features/incidents/mapbox-popup/mapbox-popup.component';
import { NewReportComponent } from './new-report/new-report.component';
import { NotificationService } from '@services/notification.service';
import { MessagesComponent } from './messages/messages.component';
// TODO:
// disabled as module import, using CDN instead. issues with typescript @types/mapbox-gl for setConfigProperty (disable POI labels)
// check new versions, or create custom wrapper for setConfigProperty?
import mapboxgl from 'mapbox-gl';
import { AuthService, DummyUser } from '@app/services/auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ListPasportComponent } from "./list-pasport/list-pasport.component";
import { FilterPasportRvoComponent } from "./filter-pasport-rvo/filter-pasport-rvo.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DashboardFullComponent } from "./dashboard-full/dashboard-full.component";
import { PasportNewDeviceComponent } from "./pasport-new-device/pasport-new-device.component";
// import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
// import * as mapbox from '@mapbox/mapbox-sdk';
//const geocodingClient = mapbox({ accessToken: 'YOUR_MAPBOX_ACCESS_TOKEN' }).geocoding;

interface MapboxDatasource {
  type: string;
  features: Feature[];
}
interface Feature {
  id: number;
  type: string;
  properties: Properties;
  geometry: Geometry;
}
interface Properties {
  id: number | undefined;
  name: string | undefined;
  incidentId: number | undefined;
  taskId: number | undefined;
  deviceTypeName: string | undefined;
  source: string | undefined;
  category: string | undefined;
}

interface MapboxDatasourcePasportItems {
  type: string;
  features: FeaturePasportItem[];
}
interface FeaturePasportItem {
  uuid: string;
  properties: PropertiesPasportItem;
  geometry: Geometry;
}
interface PropertiesPasportItem {
  uuid: string | undefined;
  type: string | undefined;
}

interface Geometry {
  type: string;
  coordinates: number[];
}

// interface for geocoder response
interface GeocoderFeature {
  id: string;
  type: string;
  place_type: string[];
  relevance: number;
  properties: GeocoderProperties;
  text: string;
  place_name: string;
  matching_place_name: string;
  center: number[];
  geometry: GeocoderGeometry;
  address?: string;
  context: GeocoderContext[];
}

interface GeocoderProperties {
  accuracy: string;
  mapbox_id: string;
}

interface GeocoderGeometry {
  type: string;
  coordinates: number[];
  interpolated?: boolean;
  omitted?: boolean;
}

interface GeocoderContext {
  id: string;
  mapbox_id: string;
  wikidata?: string;
  short_code?: string;
  text: string;
}

//declare var mapboxgl: any;

@Component({
  selector: 'app-incidents',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FilterComponent, ListComponent, ListPasportComponent, TableComponent, TablePasportComponent, DetailsComponent, PasportDetailsComponent, MaintenanceComponent, QrCodeScannerComponent, NewReportComponent, MessagesComponent, FormsModule, ListPasportComponent, FilterPasportRvoComponent, DashboardComponent, DashboardFullComponent, PasportNewDeviceComponent],
  templateUrl: './incidents.component.html',
  styleUrl: './incidents.component.css'
})
export class IncidentsComponent implements OnInit, OnDestroy {
  @ViewChild('popupContainer', { read: ViewContainerRef }) container: ViewContainerRef | undefined;
  @ViewChild('drawerMenuRef') drawerMenuRef: ElementRef | undefined;
  @ViewChild('drawerFilterRef') drawerFilterRef: ElementRef | undefined;
  @ViewChild('drawerMapRef') drawerMapRef: ElementRef | undefined;
  @ViewChild('drawerTableRef') drawerTableRef: ElementRef | undefined;
  @ViewChild('drawerMessagesRef') drawerMessagesRef: ElementRef | undefined;
  @ViewChild('drawerPasportDetailsRef') drawerPasportDetailsRef: ElementRef | undefined;
  @ViewChild('drawerDashboardRef') drawerDashboardRef: ElementRef | undefined;
  @ViewChild('dashboardComponent') dashboardComponent: DashboardComponent | undefined;
  @ViewChild('drawerPasportNewDeviceRef') drawerPasportNewDeviceRef: ElementRef | undefined;
  @ViewChild('pasportNewDeviceComponent') pasportNewDeviceComponent: PasportNewDeviceComponent | undefined;

  ACCESS_TOKEN: string = `pk.eyJ1Ijoiam9obnlkZWUiLCJhIjoiY2sxYWl6aWQ0MjV4MjNucHlhdnZ0dnE0MSJ9.GKkf5B4N3vWd98SKpvRPAQ`;
  //map!: mapboxgl.Map;
  map: any;
  // geocoder!: MapboxGeocoder;
  // list of markers to be displayed on the map
  markers: mapboxgl.Marker[] = [];
  addressMarker: mapboxgl.Marker | undefined;
  settingsRecordsPerPage: number = 0;
  // list of all incidents
  incidents: Incident[] = [];
  // list of all incidents, filtered
  incidentsFiltered: Incident[] = [];
  // list of all incidents, filtered, paged
  incidentsFilteredPaged: Incident[] = [];
  incidentsCount: number = 0;
  // TODO: use this to set active and total pages for paging
  incidentsPagesTotal: number = 0;
  incidentsPagesActive: number = 0;
  incidentsChangeDetected: boolean = false;
  tasks: Task[] = [];
  searchForm: FormGroup = new FormGroup({});
  inputMapSearchAddress: FormControl = new FormControl('');
  inputMapFilterFulltext: FormControl = new FormControl('');
  selectIncidentsPaging: FormControl = new FormControl('');
  selectPasportEntitiesPaging: FormControl = new FormControl('');

  userDetails: DummyUser | null = null;

  tmpTimeout: Number = 0;
  tmpTimeout2: Number = 0;

  geocoderResults: GeocoderFeature[] = [];
  // pasportItems: PasportItem[] = [];
  // pasportItemsCount: number = 0;

  pasportEntities: PasportEntity[] = [];
  pasportEntitiesCount: number = 0;
  pasportEntitiesPagesTotal: number = 0;
  pasportEntitiesPagesActive: number = 0;

  mapSettings3DTerrain: boolean = false;

  // FIXME: hardcoded for testing

  searchIncidentsPages = [
    { value: '1', label: '1', start: 1, end: 10},
    { value: '2', label: '2', start: 11, end: 20},
    { value: '3', label: '3', start: 21, end: 30},
    { value: '4', label: '4', start: 31, end: 40},
    { value: '5', label: '5', start: 41, end: 50}
  ];

  searchPasportEntitiesPages = [
    { value: '1', label: '1', start: 1, end: 10},
    { value: '2', label: '2', start: 11, end: 20},
    { value: '3', label: '3', start: 21, end: 30},
    { value: '4', label: '4', start: 31, end: 40},
    { value: '5', label: '5', start: 41, end: 50}
  ];

  mapboxDatasourceIncidents: MapboxDatasource = {
    type: '',
    features: []
  };
  mapboxDatasourcePasportItems: MapboxDatasourcePasportItems = {
    type: '',
    features: []
  };

  mapboxSelectedLayer: string = 'incidents';

  isGridLayoutFull: boolean = true;

  settingsPassword: string = '';
  settingsPasswordNew: string = '';
  settingsPasswordNewRepeat: string = '';
  isChangingPassword: boolean = false;
  passwordChangeError: boolean = false;

  fetchApiGetIncidentsProcessing: Observable<boolean> = new Observable<boolean>();
  fetchApiGetPasportEntitiesProcessing: Observable<boolean> = new Observable<boolean>();

  constructor(
    private router: Router,
    private dataService: DataService,
    private stateManagementService: StateManagementService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    initFlowbite();

    this.searchForm = new FormGroup({
      inputMapSearchAddress: this.inputMapSearchAddress,
      inputMapFilterFulltext: this.inputMapFilterFulltext,
      selectIncidentsPaging: this.selectIncidentsPaging,
      selectPasportEntitiesPaging: this.selectPasportEntitiesPaging
    });

    this.stateManagementService.getSettingsRecordsPerPage().subscribe(value => {
      this.settingsRecordsPerPage = value;
    });
    this.stateManagementService.getMapSettings3DTerrain().subscribe(value => {
      this.mapSettings3DTerrain = value;
    });

    this.dataService.getIncidentsPagesActive().subscribe(value => {
      this.incidentsPagesActive = value;
      // TODO: do we need to react on change here?
      this.selectIncidentsPaging.setValue((this.incidentsPagesActive).toString(), { emitEvent: false });
    });
    this.dataService.getIncidentsPagesTotal().subscribe(value => {
      this.incidentsPagesTotal = value;
      this.updateUiSelectIncidentPaging();
    });
    // do action on incdidents dataset change
    // TODO: this is tgriggered multiple times, that is not optimal
    this.dataService.getIncidentsFiltered().subscribe((data: Incident[]) => {
      // FIXME: change this to incidentsFiltered
      this.incidents = data;
      this.incidentsCount = this.incidents.length;
      // show all incidents on map
      this.showIncidentsOnMap(this.incidents);
      this.updateUiSelectIncidentPaging();
    });
    this.dataService.getIncidentsFilteredPaged().subscribe((data: Incident[]) => {
      this.incidentsFilteredPaged = data;
    });
    this.dataService.getIncidentsChangeDetected().subscribe((data: boolean) => {
      this.incidentsChangeDetected = data;
    });

    this.dataService.getTasks().subscribe((data: Task[]) => {
      this.tasks = data;
    });

    // TODO: deprecated. using filtered version now?
    // this.dataService.getPasportItems().subscribe((data: PasportItem[]) => {
    //   this.pasportItems = data;
    //   this.pasportItemsCount = this.pasportItems.length;
    //   this.showPasportItemsOnMap(this.pasportItems);
    // });

    this.dataService.getPasportEntitiesPagesActive().subscribe(value => {
      this.pasportEntitiesPagesActive = value;
      // TODO: do we need to react on change here?
      this.selectPasportEntitiesPaging.setValue((this.pasportEntitiesPagesActive).toString(), { emitEvent: false });
    });
    this.dataService.getPasportEntitiesPagesTotal().subscribe(value => {
      this.pasportEntitiesPagesTotal = value;
      this.updateUiSelectPasportEntitiesPaging();
    });

    this.dataService.getPasportEntitiesFiltered().subscribe((data: PasportEntity[]) => {
      this.pasportEntities = data;
      this.pasportEntitiesCount = data.length;
      this.showPasportEntitiesOnMap(data);
      this.updateUiSelectPasportEntitiesPaging();
    });

    this.authService.getUserDetails().subscribe((data: DummyUser | null) => {
      this.userDetails = data;
    });


    this.fetchApiGetIncidentsProcessing = this.dataService.fetchApiGetIncidentsProcessing.asObservable();
    this.fetchApiGetPasportEntitiesProcessing = this.dataService.fetchApiGetPasportEntitiesProcessing.asObservable();


    this.subscribeToFulltextChanges();
    this.subscribeToPagingChanges();

    //this.initMap();

    // Start the polling mechanism
    // this.dataService.startPolling();
  }

  private updateUiSelectPasportEntitiesPaging() {
    this.searchPasportEntitiesPages = [];
    for (let i = 1; i <= this.pasportEntitiesPagesTotal; i++) {
      this.searchPasportEntitiesPages.push(
        {
          value: i.toString(),
          label: i.toString(),
          start: (i - 1) * this.dataService.settingsRecordsPerPage + 1,
          end: (i * this.dataService.settingsRecordsPerPage > this.pasportEntitiesCount ? this.pasportEntitiesCount : i * this.dataService.settingsRecordsPerPage)
        });
    }
    // update active page
    // TODO: do smart active page decision, do not use reset page to 1
    if (this.pasportEntitiesPagesTotal > 0) {
      // Manually trigger change detection because of asynchronous nature of DOM rendering
      this.cdr.detectChanges();
      // set active page to 1, but do not trigger event for a manual page change
      this.selectPasportEntitiesPaging.setValue((this.pasportEntitiesPagesActive).toString(), { emitEvent: false });
    }
  }

  private updateUiSelectIncidentPaging() {
    this.searchIncidentsPages = [];
    for (let i = 1; i <= this.incidentsPagesTotal; i++) {
      this.searchIncidentsPages.push(
        {
          value: i.toString(),
          label: i.toString(),
          start: (i - 1) * this.dataService.settingsRecordsPerPage + 1,
          end: (i * this.dataService.settingsRecordsPerPage > this.incidentsCount ? this.incidentsCount : i * this.dataService.settingsRecordsPerPage)
        });
    }
    // update active page
    // TODO: do smart active page decision, do not use reset page to 1
    if (this.incidentsPagesTotal > 0) {
      // Manually trigger change detection because of asynchronous nature of DOM rendering
      this.cdr.detectChanges();
      // set active page to 1, but do not trigger event for a manual page change
      this.selectIncidentsPaging.setValue((this.incidentsPagesActive).toString(), { emitEvent: false });
    }
  }

  ngAfterViewInit() {
    this.initMap();
  }


  private subscribeToFulltextChanges() {
    this.inputMapFilterFulltext.valueChanges.subscribe(value => {
      this.stateManagementService.setMapFilterFulltextState(value ?? '');
    });
  }
  private subscribeToPagingChanges() {
    this.selectIncidentsPaging.valueChanges.subscribe(value => {
      //this.stateManagementService.setMapFilterIncidentsPagingState(value ?? '');
      this.dataService.updateIncidentsPagesActive(parseInt(value) ?? 0);
    });
    this.selectPasportEntitiesPaging.valueChanges.subscribe(value => {
      //this.stateManagementService.setMapFilterPasportEntitiesPagingState(value ?? '');
      this.dataService.updatePasportEntitiesPagesActive(parseInt(value) ?? 0);
    });
  }

  initMap(): void {
    this.map = new mapboxgl.Map({
      // style: 'mapbox://styles/mapbox/light-v11', // without buildings
      container: 'map',
      center: [18.739, 49.223],
      zoom: 15.5,
      pitch: 60,
      accessToken: this.ACCESS_TOKEN
    });

    this.map.on('style.load', () => {
      this.map.addSource('mapbox-dem', {
        'type': 'raster-dem',
        'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
        'tileSize': 512,
        'maxzoom': 14
      });
      // add the DEM source as a terrain layer with exaggerated height
      if (this.mapSettings3DTerrain) {
        this.map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });
      }

      // turn off POI labels. using mapbox CDN instead of module import because of issues with typescript @types/mapbox-gl
      this.map.setConfigProperty('basemap', 'showPointOfInterestLabels', false);

      // FIXME: testing layers
      const style = this.map.getStyle();
      const layers = style.layers;
    });

    this.map.on('load', () => {
      this.map.resize();
      this.onMapboxSelectedLayerChange(this.mapboxSelectedLayer);

      // Add a new source from the GeoJSON data
      this.map.addSource('markers', {
        type: 'geojson',
        data: this.mapboxDatasourceIncidents,
        cluster: true,
        clusterMaxZoom: 22, // Max zoom to cluster points (22 for mapbox max zoom level)
        clusterRadius: 15 // Radius of each cluster in pxiels
      });
      this.map.addSource('pasportMarkers', {
        type: 'geojson',
        data: this.mapboxDatasourcePasportItems,
        cluster: true,
        clusterMaxZoom: 22, // Max zoom to cluster points (22 for mapbox max zoom level)
        clusterRadius: 15 // Radius of each cluster in pxiels
      });

      // Add a layer for clusters
      this.map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'markers',
        filter: ['has', 'point_count'],
        paint: {
          // Use step expressions to define cluster circle color based on number of points
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            10,'#51bbd6',
            50,'#51bbd6'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            10,
            15,10,
            30,10
          ],
          'circle-stroke-width': 3,
          'circle-stroke-color': '#fff'
        },
        layout: {
          'visibility': 'none'
        }
      });

      // Add a layer for cluster labels
      this.map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'markers',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
          'visibility': 'none'
        },
        paint: {
          'text-color': '#fff'
        }
      });

      // Add a layer for unclustered points
      this.map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'markers',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': [
            'match',
            ['get', 'state'], // Get the 'state' property from each feature
            'new', '#2196f3', // Color for 'new'
            'inprogress', '#ffeb3b', // Color for 'inprogress'
            'solved', '#4caf50', // Color for 'solved'
            'pending', '#BB00FF', // Color for 'pending' FIXME: change color
            'unresolved', '#FFA500', // Color for 'unresolved'
            'terminated', '#640000', // Color for 'terminated'
            '#123456' // Default color if no match is found
          ],
          'circle-radius': 10,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#fff'
        },
        layout: {
          'visibility': 'none'
        }
      });


      // Add a layer for clusters pasport
      this.map.addLayer({
        id: 'clusters-pasport',
        type: 'circle',
        source: 'pasportMarkers',
        filter: ['has', 'point_count'],
        paint: {
          // Use step expressions to define cluster circle color based on number of points
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            10,'#51bbd6',
            50,'#51bbd6'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            10,
            15,10,
            30,10
          ],
          'circle-stroke-width': 3,
          'circle-stroke-color': '#fff'
        },
        layout: {
          'visibility': 'none'
        }
      });

      // Add a layer for cluster labels pasport
      this.map.addLayer({
        id: 'cluster-count-pasport',
        type: 'symbol',
        source: 'pasportMarkers',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
          'visibility': 'none'
        },
        paint: {
          'text-color': '#fff'
        }
      });

      // Add a layer for unclustered points - pasport items
      this.map.addLayer({
        id: 'unclustered-point-pasport',
        type: 'circle',
        source: 'pasportMarkers',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': [
            'match',
            ['get', 'type'], // Get the 'state' property from each feature
            'rvo', '#FF0000', // Color for 'rvo'
            'lightpoint', '#00FF00', // Color for 'lightpoint'
            '#0000FF' // Default color if no match is found
          ],
          'circle-radius': 10,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#fff'
        },
        layout: {
          'visibility': 'none'
        }
      });


      this.map.addLayer({
        id: 'unclustered-point-hover',
        type: 'circle',
        source: 'markers',
        filter: ['all', ['!', ['has', 'point_count']], ['==', ['id'], '']],
        //filter: ['all', ['==', ['id'], '']],
        paint: {
          'circle-color': '#000', // Change color to black on hover
          'circle-radius': 14, // Increase radius on hover
          'circle-stroke-width': 3,
          'circle-stroke-color': '#fff'
        },
        layout: {
          'visibility': 'none'
        }
      }, 'unclustered-point'); // Insert the hover layer below the unclustered point layer

      this.map.addLayer({
        id: 'unclustered-point-pasport-hover',
        type: 'circle',
        source: 'pasportMarkers',
        //filter: ['all', ['!', ['has', 'point_count']], ['==', ['uuid'], '']],
        //filter: ['all', ['!', ['has', 'point_count']], ['==', 'uuid', '']],
        filter: ['all', ['none', ['has', 'point_count']], ['==', 'uuid', '']],
        paint: {
          'circle-color': '#000', // Change color to black on hover
          'circle-radius': 14, // Increase radius on hover
          'circle-stroke-width': 3,
          'circle-stroke-color': '#fff'
        },
        layout: {
          'visibility': 'none'
        }
      }, 'unclustered-point-pasport'); // Insert the hover layer below the unclustered point layer

      this.showIncidentsOnMap(this.incidents);
    });

    // onclick event for clusters to show popup with a list of individual incidents
    this.map.on('click', 'clusters', (e: mapboxgl.MapMouseEvent) => {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ['clusters']
      });
      const clusterId = features[0].properties.cluster_id;
      const limit = 100000; // limit of markers to show
      const offset = 0; // offset of the first marker

      let popup: mapboxgl.Popup;

      this.map.setPaintProperty('clusters', 'circle-stroke-color', [
        'match', ['get','cluster_id'], '',
        '#000000', // Color for the clicked feature
        '#ffffff' // Default color for other features
      ]);
      this.map.setPaintProperty('unclustered-point', 'circle-stroke-color', [
        'match',
        ['get','objectId'], '',
        '#000000', // Color for the clicked feature
        '#ffffff' // Default color for other features
      ]);

      this.map.setPaintProperty('clusters', 'circle-stroke-color', [
        'match',
        ['get','cluster_id'],
        features[0].properties.cluster_id, '#000000', // Color for the clicked feature
        '#ffffff' // Default color for other features
      ]);

      this.map.getSource('markers').getClusterLeaves(clusterId, limit, offset, (err: Error, leaves: any[]) => {
        if (err) {
          return console.error('Error getting leaves of cluster', err);
        }
        this.openPopup(leaves, e);
        return;
      });
    });

    // onclick event for individual incidents / markers
    this.map.on('click', 'unclustered-point', (e: mapboxgl.MapMouseEvent) => {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ['unclustered-point'] // replace with your unclustered layer id
      });

      this.map.setPaintProperty('clusters', 'circle-stroke-color', [
        'match', ['get','cluster_id'], '',
        '#000000', // Color for the clicked feature
        '#ffffff' // Default color for other features
      ]);
      this.map.setPaintProperty('unclustered-point', 'circle-stroke-color', [
        'match',
        ['get','objectId'], '',
        '#000000', // Color for the clicked feature
        '#ffffff' // Default color for other features
      ]);

      this.map.setPaintProperty('unclustered-point', 'circle-stroke-color', [
        'match',
        ['get','objectId'],
        features[0].properties.objectId, '#000000', // Color for the clicked feature
        '#ffffff' // Default color for other features
      ]);

      if (features[0].properties.objectId) {
        //this.dataService.fetchApiGetPasportEntity(features[0].properties.uuid);
        this.openPopup(
          {
            type: 'incident',
            cluster: false,
            data: features[0].properties
          },
          e
        );
      }

      return;
    });
    this.map.on('mouseenter', 'unclustered-point', (e: mapboxgl.MapMouseEvent) => {
      // Change the cursor style as a UI indicator.
      this.map.getCanvas().style.cursor = 'pointer';

      // Retrieve features under the mouse pointer
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ['unclustered-point'] // replace with your unclustered layer id
      });

      // Set the filter for the hover layer to only show the feature under the mouse
      this.map.setFilter('unclustered-point-hover', ['==', 'objectId', features[0].properties.objectId]);
      this.tmpTimeout = setTimeout(() => {
        this.map.setFilter('unclustered-point-hover', ['==', ['id'], features[0].id]);
      }, 2000) as any;
    });
    this.map.on('mouseleave', 'unclustered-point', () => {
      try {
        clearTimeout(this.tmpTimeout as any);
      } catch (error) { }
      this.map.getCanvas().style.cursor = '';
      // Reset the filter for the hover layer to hide it
      this.map.setFilter('unclustered-point-hover', ['==', ['id'], '']);
    });

    // onclick event for clusters to show popup with a list of individual incidents
    this.map.on('click', 'clusters-pasport', (e: mapboxgl.MapMouseEvent) => {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ['clusters-pasport']
      });
      const clusterId = features[0].properties.cluster_id;
      const limit = 100000; // limit of markers to show
      const offset = 0; // offset of the first marker

      let popup: mapboxgl.Popup;

      this.map.setPaintProperty('clusters-pasport', 'circle-stroke-color', [
        'match', ['get','cluster_id'], '',
        '#000000', // Color for the clicked feature
        '#ffffff' // Default color for other features
      ]);
      this.map.setPaintProperty('unclustered-point-pasport', 'circle-stroke-color', [
        'match',
        ['get','objectId'], '',
        '#000000', // Color for the clicked feature
        '#ffffff' // Default color for other features
      ]);

      this.map.setPaintProperty('clusters-pasport', 'circle-stroke-color', [
        'match',
        ['get','cluster_id'],
        features[0].properties.cluster_id, '#000000', // Color for the clicked feature
        '#ffffff' // Default color for other features
      ]);

      this.map.getSource('pasportMarkers').getClusterLeaves(clusterId, limit, offset, (err: Error, leaves: any[]) => {
        if (err) {
          return console.error('Error getting leaves of cluster', err);
        }
        this.openPopup(
          {
            type: 'pasport',
            cluster: true,
            data: leaves
          },
          e
        );
        return;
      });
    });
    this.map.on('click', 'unclustered-point-pasport', (e: mapboxgl.MapMouseEvent) => {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ['unclustered-point-pasport'] // replace with your unclustered layer id
      });

      if (features[0].properties.uuid) {
        //this.dataService.fetchApiGetPasportEntity(features[0].properties.uuid);
        this.openPopup(
          {
            type: 'pasport',
            cluster: false,
            data: features[0].properties
          },
          e
        );
      }
    });
    this.map.on('mouseenter', 'unclustered-point-pasport', (e: mapboxgl.MapMouseEvent) => {
      // Change the cursor style as a UI indicator.
      this.map.getCanvas().style.cursor = 'pointer';

      // Retrieve features under the mouse pointer
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ['unclustered-point-pasport'] // replace with your unclustered layer id
      });

      // Set the filter for the hover layer to only show the feature under the mouse
      // this.map.setFilter('unclustered-xxxpoint-hover', ['==', ['id'], features[0].id]);
      this.map.setFilter('unclustered-point-pasport-hover', ['==', 'uuid', features[0].properties.uuid]);
      this.tmpTimeout2 = setTimeout(() => {
        this.map.setFilter('unclustered-point-pasport-hover', ['==', ['uuid'], features[0].uuid]);
      }, 2000) as any;
    });
    this.map.on('mouseleave', 'unclustered-point-pasport', () => {
      try {
        clearTimeout(this.tmpTimeout2 as any);
      } catch (error) { }
      this.map.getCanvas().style.cursor = '';
      // Reset the filter for the hover layer to hide it
      //this.map.setFilter('unclustered-point-pasport-hover', ['==', ['uuid'], '']);
      this.map.setFilter('unclustered-point-pasport-hover', ['==', 'uuid', '']);
    });

  }

  toggleTerrain() {
    const terrain = this.map.getTerrain();
    if (terrain) {
      this.map.setTerrain(null);
    } else {
      this.map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });
    }
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.remove();
    }
  }

  // Property to control visibility
  isFilterVisible: boolean = false;
  isListVisible: boolean = false;

  openPopup(data: any, e: mapboxgl.MapMouseEvent) {
    if (this.container) {
      const componentRef = this.container.createComponent(MapboxPopupComponent);
      (componentRef.instance as MapboxPopupComponent).data = data;

      (componentRef.instance as MapboxPopupComponent).onClosePopup.subscribe(() => {
        // Logic to close the popup
        popup.remove();
      });

      (componentRef.instance as MapboxPopupComponent).onClickDetails.subscribe(async (id) => {
        // Logic to show details for the incident

        // we can you existing function but we need to find the incident by id
        // this.showDetails(incident);

        // do the same as for function showDetails
        this.dataService.updateTask(null);
        this.dataService.fetchApigetIncident(id);
        this.dataService.fetchApiGetIncidentTasks(id);
        // FIXME: This is a hack to show the details component
        document.getElementById('testButtonShowDetails')!.click();
        return;
      });

      (componentRef.instance as MapboxPopupComponent).onClickNewIncident.subscribe(async (uuid) => {
        // fetch pasport data
        this.dataService.fetchApiGetPasportEntity(uuid);
        // TODO: this is not the best approach (clone from main menu)
        this.onMainMenuNewReportClicked();
        return;
      });

      (componentRef.instance as MapboxPopupComponent).onClickPasportDetails.subscribe(async (uuid) => {
        // fetch pasport data
        // this.dataService.fetchApiGetPasportEntity(uuid);
        // TODO: this is not the best approach (clone from main menu)
        this.showPasportDetails(uuid);
        return;
      });

      const popup = new mapboxgl.Popup()
        .setLngLat(e.lngLat)
        .setDOMContent(componentRef.location.nativeElement)
        .addTo(this.map);
    }
  }

  // Function to toggle visibility
  toggleVisibility(target: string): void {
    if (target === 'filter') {
      this.isFilterVisible = !this.isFilterVisible;
    } else if (target === 'list') {
      this.isListVisible = !this.isListVisible;
    }
  }
  hideVisibility(target: string): void {
    if (target === 'filter') {
      this.isFilterVisible = false;
    } else if (target === 'list') {
      this.isListVisible = false;
    }
  }

  onDetailClicked(incident: Incident) {
    // handle the event here
    this.showDetails(incident);
  }

  onDetailsPasportClicked(entity: PasportEntity) {
    this.showPasportDetails(entity.uuid);
  }

  /**
   * Scrolls to the specified incident in the table and list views.
   * @param incident - The incident to scroll to.
   */
  onScrollToClicked(incident: Incident) {
    const rowElementTable = document.querySelector(`#table-incidents-row-${incident.id}`);
    if (rowElementTable) {
      rowElementTable.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const rowElementList = document.querySelector(`#list-incidents-row-${incident.id}`);
    if (rowElementList) {
      rowElementList.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  onMaintenanceClicked(task: Task) {
    this.showMaintenance(task);
  }
  onQrCodeScannerClicked(task: Task) {
    this.showQrCodeScanner(task);
  }
  onQrCodeScannerNewReportClicked() {
    this.showQrCodeScannerForNewReport();
  }
  onShowOnMapClicked(incident: Incident) {
    // if (this.incidents.length > 2) {
    this.toggleDrawer('drawer-table');
    // this.hideVisibility('list');
    // this.hideVisibility('table');
    // }
    this.highlightMarker(incident);
    this.centerMapOnCoordinates(incident.tasks[0].coordinates.latitude, incident.tasks[0].coordinates.longitude);

  }
  onMainMenuNewReportClicked() {
    document.getElementById('buttonMainMenuClose')?.click();
    document.getElementById('testButtonShowNewReport')!.click();
    setTimeout(() => {
      const backdropElement = document.querySelector('[drawer-backdrop]');
      if (backdropElement) {
        backdropElement.parentNode?.removeChild(backdropElement);
      }
    }, 10);
    return;
  }

  async showDetails(incident: Incident): Promise<void> {
    this.dataService.updateIncident(null);
    this.dataService.updateTask(null);
    this.dataService.fetchApigetIncident(incident.id);
    this.dataService.fetchApiGetIncidentTasks(incident.id);
    // FIXME: This is a hack to show the details component
    document.getElementById('testButtonShowDetails')!.click();
    return;
  }

  async showMaintenance(task: Task): Promise<void> {
    // document.getElementById('testButtonShowDetails')!.click();
    // TODO: change async await decision. show maintenance even before data is loaded?
    this.dataService.updateTask(null);
    this.dataService.fetchApiGetTask(task.id);
    this.dataService.fetchApiGetMetricsTemp(task);
    this.dataService.fetchApiGetMetricsGraph(task);
    this.dataService.fetchApiGetPasportEntity(task.objectId);

    // TODO: deprecated - not using this anymore? commented out because of using device-control component
    // if (task.deviceTypeName) {
    //   const deviceTypeName = task.deviceTypeName.toUpperCase();
    //   if (deviceTypeName === 'LIGHTPOINT') {
    //   this.dataService.fetchApiPatchControlIntensity(task.objectId);
    //   } else if (deviceTypeName === 'RVO') {
    //   this.dataService.fetchApiPatchControlMode(task.objectId);
    //   }
    // }
    document.getElementById('testButtonShowMaintenance')!.click();
    return;
  }

  async showQrCodeScanner(task: Task): Promise<void> {
    this.dataService.updateQrCodeObjectName(task.objectName);
    document.getElementById('testButtonShowQrCodeScanner')!.click();
    return;
  }
  async showQrCodeScannerForNewReport(): Promise<void> {
    document.getElementById('testButtonShowQrCodeScanner')!.click();
    return;
  }


  refreshData(): void {
    if (this.mapboxSelectedLayer === 'incidents') {
      this.refreshIncidents();
    } else if (this.mapboxSelectedLayer === 'pasport') {
      this.refreshEntities();
    }
  }

  refreshIncidents(): void {

    this.dataService.fetchApiGetIncidents();
    // this.dataService.fetchApiGetPasportItems();
  }

  refreshEntities(): void {
    this.dataService.fetchApiGetPasportEntities();
  }

  /**
   * Shows incidents on the map.
   * FIXME: not used anymore, remove?
   * @param incidents - An array of incidents to be displayed on the map.
   */
  showIncidentsOnMap(incidents: Incident[]): void {
    if (!this.map) {
      return;
    }

    // Create a set to track unique incident-task combinations
    const addedMarkers = new Set();
    this.mapboxDatasourceIncidents = {
      type: '',
      features: []
    };

    this.incidents.forEach((incident) => {
      incident.tasks.forEach((task: Task) => {
        // Create a unique identifier for the incident-task combination
        //const markerIdentifier = `Incident-${incident.id}-Task-${task.id}`;
        // FIXME: use objectId instead of taskId?
        // const markerIdentifier = `Incident-${incident.id}-Task-${task.taskId}`;
        const markerIdentifier = `Incident-${incident.id}-${task.objectId}`;

        // Check if this combination has already been added
        if (!addedMarkers.has(markerIdentifier)) {
          // Add marker to the map
          // TODO: reslve category usage
          let newFeature = {
            id: incident.id,
            objectId: task.objectId,
            "type": "Feature",
            "properties": {
              id: incident.id,
              incidentId: incident.id,
              taskId: task.id,
              name: incident.name,
              deviceTypeName: task.deviceTypeName,
              source: task.source,
              category: 'category5',
              objectId: task.objectId,
              address: incident.address,
              objectName: task.objectName,
              state: incident.state
            },
            "geometry": {
              "type": "Point",
              "coordinates": [task?.coordinates?.longitude, task?.coordinates?.latitude]
            }
          };
          this.mapboxDatasourceIncidents.features.push(newFeature);
          addedMarkers.add(markerIdentifier);
        }
      });
    });
    if (this.map.getSource('markers')) {
      this.map.getSource('markers').setData(this.mapboxDatasourceIncidents);
      this.onMapboxSelectedLayerChange(this.mapboxSelectedLayer);
    }
  }

  showPasportEntitiesOnMap(pasportEntities: PasportEntity[]): void {
    if (!this.map) {
      return;
    }

    // Create a set to track unique incident-task combinations
    const addedMarkers = new Set();
    this.mapboxDatasourcePasportItems = {
      type: '',
      features: []
    };

    this.pasportEntities.forEach((pasportEntity) => {

      const markerIdentifier = `${pasportEntity.uuid}`;

      // Check if this combination has already been added
      if ((!addedMarkers.has(markerIdentifier)) && (pasportEntity.coordinates.latitude && pasportEntity.coordinates.longitude)) {
        // Add marker to the map
        // TODO: reslve category usage
        let newFeature = {
          uuid: pasportEntity.uuid,
          "properties": {
            uuid: pasportEntity.uuid,
            type: pasportEntity.type,
            name: pasportEntity.name
          },
          "geometry": {
            "type": "Point",
            "coordinates": [pasportEntity.coordinates.longitude, pasportEntity.coordinates.latitude]
          }
        };
        this.mapboxDatasourcePasportItems.features.push(newFeature);
        addedMarkers.add(markerIdentifier);
      }

    });
    if (this.map.getSource('pasportMarkers')) {
      this.map.getSource('pasportMarkers').setData(this.mapboxDatasourcePasportItems);
    }
  }

  clearMarkers(): void {
    this.markers.forEach(marker => marker.remove());
    this.markers = [];
  }

  highlightMarker(incident: Incident): void {
    // reset cluster and unclustered-point colors
    this.map.setPaintProperty('clusters', 'circle-stroke-color', [
      'match', ['get','cluster_id'], '',
      '#000000', // Color for the clicked feature
      '#ffffff' // Default color for other features
    ]);
    this.map.setPaintProperty('unclustered-point', 'circle-stroke-color', [
      'match',
      ['get','objectId'], '',
      '#000000', // Color for the clicked feature
      '#ffffff' // Default color for other features
    ]);

    // try to find marker in unclustered-points
    const objectId = incident.tasks[0].objectId;

    const unclusteredPoints = this.map.queryRenderedFeatures({ layers: ['unclustered-point'] });
    const pointExists = unclusteredPoints.some((feature: any) => {
      return feature.properties.objectId == objectId;
    });

    if (objectId && pointExists) {
      this.map.setPaintProperty('unclustered-point', 'circle-stroke-color', [
        'match',
        ['get','objectId'],
        objectId, '#000000', // Color for the clicked feature
        '#ffffff' // Default color for other features
      ]);
    } else {
      // if marker does not exist in unclustered-points, try to find it in clusters

      // TODO: dev try to find cluster
      // Query all cluster features in the current map view
      const clusterFeatures = this.map.querySourceFeatures('markers', {
        sourceLayer: 'clusters', // Adjust this if your source has a different layer name
        filter: ['has', 'point_count'] // This ensures we only get cluster features
      });

      const lat = incident.tasks[0].coordinates.latitude;
      const lng = incident.tasks[0].coordinates.longitude;
      let minDistance = 100000000;
      let clusterId:number = -1;
      clusterFeatures.forEach((feature: any) => {
        const distance = this.calculateDistanceInMeters(lat, lng, feature.geometry.coordinates[1], feature.geometry.coordinates[0]);
        if (distance < minDistance) {
          minDistance = distance;
          clusterId = feature.properties.cluster_id;
        }
      });

      if (clusterId > -1) {
        this.map.setPaintProperty('clusters', 'circle-stroke-color', [
          'match',
          ['get','cluster_id'],
          clusterId, '#000000', // Color for the clicked feature
          '#ffffff' // Default color for other features
        ]);
      }
    }

  }

  private calculateDistanceInMeters(lat1: number, lon1: number, lat2: number, lon2: number) {
    // Radius of the Earth in meters
    const R = 6371000;

    // Convert degrees to radians
    const rad = (deg: any) => deg * (Math.PI / 180);

    // Latitude and longitude differences in radians
    const dLat = rad(lat2 - lat1);
    const dLon = rad(lon2 - lon1);

    // Apply the Haversine formula
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(lat1)) * Math.cos(rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Distance in meters
    const distance = R * c;

    return distance;
  }

  centerMapOnCoordinates(latitude: number, longitude: number): void {
    if (this.map) {
      this.map.flyTo({
        center: [longitude, latitude],
        //zoom: 10, // You can adjust the zoom level as needed
        speed: 1.2, // Adjust the speed of the transition (default is 1.2)
        curve: 1.42, // Adjust the curve of the transition (default is 1.42)
        easing: (t: number) => t, // Adjust the easing function for the transition (default is (t: number) => t)
      });
    }
  }

  /**
   * Handles the change event of the filter.
   * Fetches incidents using the data service.
   * @returns A promise that resolves to a boolean value indicating the success of the operation.
   */
  async handleFilterChange(): Promise<boolean> {
    // TODO: implement client side vs server side filtering
    // await this.dataService.fetchApiGetIncidents();
    return true;
  }

  public downloadExcel(): void {
    console.log("Downloading excel file.");
    let ids: number[] = [];
    this.incidents.forEach(entity => {
      ids.push(entity.id);
    });
    this.dataService.fetchApiPostExcel(ids).then(
      result => {
        if (result && result instanceof Blob) {
          let filename: string = "incidents.xlsx";
          let binaryData = [result];
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'text/excel' }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          console.log("File downloaded successfully.");
        } else {
          console.error("Response body is not of type Blob.");
        }
      },
      error => {
        console.error("Error while downloading excel file.");
        console.error(error);
      }
    );
  }

  async searchAddress() {
    this.geocoderResults = [];
    if (this.searchForm.valid) {
      try {
        // TODO: hardcoded address for slovakia
        const address = `${this.inputMapSearchAddress.value} slovensko`;
        const encodedAddress = encodeURIComponent(address);
        const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedAddress}.json?access_token=${this.ACCESS_TOKEN}`;

        const response = await fetch(url);
        const json = await response.json();
        // extract only features that are type of address
        json.features = json.features.filter((feature: any) => feature.place_type.includes('address'));
        this.geocoderResults = json.features;
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }

  gotoAddress(item: GeocoderFeature) {
    this.addressMarker?.remove();
    this.addressMarker = new mapboxgl.Marker()
      .setLngLat([item.center[0], item.center[1]])
      .addTo(this.map);
    this.centerMapOnCoordinates(item.center[1], item.center[0]);
  }

  prevPage() {
    let currentValue = +this.selectIncidentsPaging.value;

    if (currentValue > 1) {
      this.selectIncidentsPaging.setValue((currentValue - 1).toString(), { emitEvent: true });
    }
  }
  nextPage() {
    let currentValue = +this.selectIncidentsPaging.value;
    if ((currentValue > 0) && (currentValue < this.incidentsPagesTotal)) {
      this.selectIncidentsPaging.setValue((currentValue + 1).toString(), { emitEvent: true });
    }
  }

  prevPagePasportEntities() {
    let currentValue = +this.selectPasportEntitiesPaging.value;
    if (currentValue > 1) {
      this.selectPasportEntitiesPaging.setValue((currentValue - 1).toString(), { emitEvent: true });
    }
  }
  nextPagePasportEntities() {
    let currentValue = +this.selectPasportEntitiesPaging.value;
    if ((currentValue > 0) && (currentValue < this.pasportEntitiesPagesTotal)) {
      this.selectPasportEntitiesPaging.setValue((currentValue + 1).toString(), { emitEvent: true });
    }
  }

  logout(event: MouseEvent):void {
    event.preventDefault();
    this.authService.logout();
  }

  public toggleLayoutType(layoutType: string): void {
    if (layoutType === 'wide') {
      this.isGridLayoutFull = true;
    } else {
      this.isGridLayoutFull = false;
    }
  }

  async onMapboxSelectedLayerChange(newValue: string) {
    // Additional logic to handle layer change...
    if (newValue === 'incidents') {
      if (this.map.getLayer('clusters')) {
        this.map.setLayoutProperty('clusters', 'visibility', 'visible');
      }
      if (this.map.getLayer('cluster-count')) {
        this.map.setLayoutProperty('cluster-count', 'visibility', 'visible');
      }
      if (this.map.getLayer('unclustered-point')) {
        this.map.setLayoutProperty('unclustered-point', 'visibility', 'visible');
      }
      if (this.map.getLayer('unclustered-point-hover')) {
        this.map.setLayoutProperty('unclustered-point-hover', 'visibility', 'visible');
      }

      if (this.map.getLayer('clusters-pasport')) {
        this.map.setLayoutProperty('clusters-pasport', 'visibility', 'none');
      }
      if (this.map.getLayer('cluster-count-pasport')) {
        this.map.setLayoutProperty('cluster-count-pasport', 'visibility', 'none');
      }
      if (this.map.getLayer('unclustered-point-pasport')) {
        this.map.setLayoutProperty('unclustered-point-pasport', 'visibility', 'none');
      }
      if (this.map.getLayer('unclustered-point-pasport-hover')) {
        this.map.setLayoutProperty('unclustered-point-pasport-hover', 'visibility', 'none');
      }
    } else if (newValue === 'pasport') {
      if (!(this.pasportEntitiesCount > 0)) {
        await this.dataService.fetchApiGetPasportEntities();
      }
      if (this.map.getLayer('clusters')) {
        this.map.setLayoutProperty('clusters', 'visibility', 'none');
      }
      if (this.map.getLayer('cluster-count')) {
        this.map.setLayoutProperty('cluster-count', 'visibility', 'none');
      }
      if (this.map.getLayer('unclustered-point')) {
        this.map.setLayoutProperty('unclustered-point', 'visibility', 'none');
      }
      if (this.map.getLayer('unclustered-point-hover')) {
        this.map.setLayoutProperty('unclustered-point-hover', 'visibility', 'none');
      }

      if (this.map.getLayer('clusters-pasport')) {
        this.map.setLayoutProperty('clusters-pasport', 'visibility', 'visible');
      }
      if (this.map.getLayer('cluster-count-pasport')) {
        this.map.setLayoutProperty('cluster-count-pasport', 'visibility', 'visible');
      }
      if (this.map.getLayer('unclustered-point-pasport')) {
        this.map.setLayoutProperty('unclustered-point-pasport', 'visibility', 'visible');
      }
      if (this.map.getLayer('unclustered-point-pasport-hover')) {
        this.map.setLayoutProperty('unclustered-point-pasport-hover', 'visibility', 'visible');
      }
    }
  }

  //
  // FIXME: show functionality using button click
  async sidebarIconToggleSection(target: string): Promise<void> {
    if (target === 'main-menu') {
      document.getElementById('testButtonShowMenu')!.click();
    } else if (target === 'map') {
      document.getElementById('testButtonShowMap')!.click();
    }

    return;
  }

  toggleDrawer(target: string): void {

    if (target === 'drawer-menu') {
      if (!this.drawerMenuRef) {
        return;
      }
      toggleLeft(this.drawerMenuRef.nativeElement);
      this.drawerMapRef!.nativeElement.classList.remove('translate-x-0');
      this.drawerMapRef!.nativeElement.classList.add('-translate-x-full');
      this.drawerFilterRef!.nativeElement.classList.remove('translate-x-0');
      this.drawerFilterRef!.nativeElement.classList.add('-translate-x-full');
    } else if (target === 'drawer-filter') {
      if (!this.drawerFilterRef) {
        return;
      }
      toggleLeft(this.drawerFilterRef.nativeElement);
      this.drawerMenuRef!.nativeElement.classList.remove('translate-x-0');
      this.drawerMenuRef!.nativeElement.classList.add('-translate-x-full');
      this.drawerMapRef!.nativeElement.classList.remove('translate-x-0');
      this.drawerMapRef!.nativeElement.classList.add('-translate-x-full');
    } else if (target === 'drawer-map') {
      if (!this.drawerMapRef) {
        return;
      }
      toggleLeft(this.drawerMapRef.nativeElement);
      this.drawerMenuRef!.nativeElement.classList.remove('translate-x-0');
      this.drawerMenuRef!.nativeElement.classList.add('-translate-x-full');
      this.drawerFilterRef!.nativeElement.classList.remove('translate-x-0');
      this.drawerFilterRef!.nativeElement.classList.add('-translate-x-full');
    } else if (target === 'drawer-table') {
      if (!this.drawerTableRef) {
        return;
      }
      toggleRight(this.drawerTableRef.nativeElement);
      this.toggleVisibility('list');
    } else if (target === 'drawer-messages') {
      if (!this.drawerMessagesRef) {
        return;
      }
      this.dataService.refreshNotificationItems();
      toggleRight(this.drawerMessagesRef.nativeElement);
    } else if (target === 'drawer-pasport-details') {
      if (!this.drawerPasportDetailsRef) {
        return;
      }
      toggleRight(this.drawerPasportDetailsRef.nativeElement);
    } else if (target === 'drawer-dashboard') {
      if (!this.drawerDashboardRef) {
        return;
      }
      toggleRight(this.drawerDashboardRef.nativeElement);
    } else if (target === 'drawer-pasport-new-device') {
      if (!this.drawerPasportNewDeviceRef) {
        return;
      }
      // reset pasport-new-device component
      if (this.pasportNewDeviceComponent) {
        this.pasportNewDeviceComponent.initializeData();
      }
      // toggle drawer
      toggleRight(this.drawerPasportNewDeviceRef.nativeElement);
    }

    /**
     * Toggles the visibility of the left drawer element.
     * If the drawer is currently visible, it will be hidden.
     * If the drawer is currently hidden, it will be shown.
     * @param drawerElement - The element representing the left drawer.
     */
    function toggleLeft(drawerElement: any) {
      if (drawerElement.classList.contains('translate-x-0')) {
        // Hide the drawer
        drawerElement.classList.remove('translate-x-0');
        drawerElement.classList.add('-translate-x-full');
      } else {
        // Show the drawer
        drawerElement.classList.remove('-translate-x-full');
        drawerElement.classList.add('translate-x-0');
      }
    }
    function toggleRight(drawerElement: any) {
      // if (drawerElement.classList.contains('translate-x-0')) {
      //   // Hide the drawer
      //   drawerElement.classList.remove('translate-x-0');
      //   drawerElement.classList.add('translate-x-full');
      // } else {
      //   // Show the drawer
      //   drawerElement.classList.remove('translate-x-full');
      //   drawerElement.classList.add('translate-x-0');
      // }
      if (drawerElement.classList.contains('transform-none')) {
        // Hide the drawer
        drawerElement.classList.remove('transform-none');
        drawerElement.classList.add('translate-x-full');
      } else {
        // Show the drawer
        drawerElement.classList.remove('translate-x-full');
        drawerElement.classList.add('transform-none');
      }
    }

    // TODO: not used anymore, remove?
    function hideAll(this: any) {
      // left drawers
      this.drawerMenuRef.nativeElement.classList.remove('translate-x-0');
      this.drawerMenuRef.nativeElement.classList.add('-translate-x-full');
      this.drawerMapRef.nativeElement.classList.remove('translate-x-0');
      this.drawerMapRef.nativeElement.classList.add('-translate-x-full');
      this.drawerFilterRef.nativeElement.classList.remove('translate-x-0');
      this.drawerFilterRef.nativeElement.classList.add('-translate-x-full');
    }

    // Optionally, re-initialize or update any necessary Flowbite or custom functionality
    // initFlowbite();
  }

  showPasportDetails(uuid: string): void {
    if (!this.drawerPasportDetailsRef) {
      return;
    }
    // TODO: load pasport data
    this.dataService.fetchApiGetPasportEntity(uuid);
    const drawerElement = this.drawerPasportDetailsRef.nativeElement;
    // if (drawerElement.classList.contains('translate-x-0')) {
    // } else {
    //   // Show the drawer
    //   drawerElement.classList.remove('translate-x-full');
    //   drawerElement.classList.add('translate-x-0');
    // }
    if (drawerElement.classList.contains('transform-none')) {
    } else {
      // Show the drawer
      drawerElement.classList.remove('translate-x-full');
      drawerElement.classList.add('transform-none');
    }
  }
  hidePasportDetails(): void {
    if (!this.drawerPasportDetailsRef) {
      return;
    }
    const drawerElement = this.drawerPasportDetailsRef.nativeElement;
    // if (drawerElement.classList.contains('translate-x-0')) {
    //   // Hide the drawer
    //   drawerElement.classList.remove('translate-x-0');
    //   drawerElement.classList.add('translate-x-full');
    // }
    if (drawerElement.classList.contains('transform-none')) {
      // Hide the drawer
      drawerElement.classList.remove('transform-none');
      drawerElement.classList.add('translate-x-full');
    }
  }

  showTestNotification(): void {
    setTimeout(() => {
      this.notificationService.sendNotification('DEV MESSAGE TITLE',
      {
        body: `Dev test message`,
        icon: "assets/icons/icon-72x72.png",
        badge: "assets/icons/icon-72x72.png",
        tag: "dev-notification",
        //renotify: false,
        //timestamp: Date.now()
      });
    }, 3000);

    // let notification =
    // {
    //   "notification": {
    //     "title": "New Notification!",
    //     "actions": [
    //       { "action": "foo", "title": "Open new tab" },
    //       { "action": "bar", "title": "Focus last" }
    //     ],
    //     "data": {
    //       "onActionClick": {
    //         "default": { "operation": "openWindow" },
    //         "foo": { "operation": "openWindow", "url": "/absolute/path" },
    //         "bar": { "operation": "focusLastFocusedOrOpen", "url": "relative/path" }
    //       }
    //     }
    //   }
    // };

    let notification =
    {
      "notification": {
        "title": "New Test Notification!",
        "actions": [
          { "action": "closeNotification", "title": "Zatvorit" }
        ],
        "data": {
          "onActionClick": {
            "default": { "operation": "focusLastFocusedOrOpen", "url": "" },
            "closeNotification": { "operation": "closeNotification" }
          }
        }
      }
    };
  }

  showNewIncidentForm(uuid: string): void {
    // fetch pasport data
    this.dataService.fetchApiGetPasportEntity(uuid);
    // TODO: this is not the best approach (clone from main menu)
    this.onMainMenuNewReportClicked();
    return;
  }

  /**
   * Resets the application to its initial state by performing the following actions:
   * - Clears the local storage.
   * - Logs out the user.
   * - Refreshes the page.
   * - Redirects to the application root.
   *
   * Note: Revoking permissions and uninstalling the PWA require direct user interaction with the operating system and may not be possible programmatically.
   */
  factoryReset(): void {
    // clear localstorage
    localStorage.clear();

    // unistall pwa
    // probably not be possible - requires a direct user interaction with operating system

    // revoke notifications permissions
    // probably not be possible - requires a direct user interaction with operating system

    // revoke camera permissions
    // probably not be possible - requires a direct user interaction with operating system

    // logout
    this.authService.logout();

    // refresh page

    // redirect to app root
    window.location.reload();
  }

  /**
   * Changes the user's password.
   *
   * @returns A Promise that resolves when the password change is complete.
   */
  async changePassword(): Promise<void> {
    this.isChangingPassword = true;
    this.passwordChangeError = false;

    // Artificial delay of 1 second
    await new Promise(resolve => setTimeout(resolve, 1000));

    // TODO: compare new passwords client sides

    // Call the service to change the password
    const success = await this.authService.changePassword(this.settingsPassword, this.settingsPasswordNew, this.settingsPasswordNewRepeat);

    this.isChangingPassword = false;
    if (success) {
      // reset form and show success message
      this.settingsPassword = '';
      this.settingsPasswordNew = '';
      this.settingsPasswordNewRepeat = '';
    } else {
      // show error message
      this.passwordChangeError = true;
    }
  }

  refreshDashboard() {
    if (this.dashboardComponent) {
      this.dashboardComponent.refresh();
    }
  }

}
