import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPasportEntityAttributes',
  standalone: true
})
export class FormatPasportEntityAttributesPipe implements PipeTransform {

  transform(value: any): string {
    if (!value) return ''; // If no data, return empty string

    // List of keys to exclude
    const excludeKeys = ['name', 'entityType', 'importUUID', 'uuid'];
    let result: string[] = [];

    // Iterate over the object and build the formatted string
    for (const key in value) {
      if (value.hasOwnProperty(key) && !excludeKeys.includes(key)) {
        result.push(`${key.charAt(0).toUpperCase() + key.slice(1)}: ${value[key]}`);
      }
    }

    // Join the result to format it with '|'
    return result.join(',');
  }
}
