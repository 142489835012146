// mapbox-popup.component.ts
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from '@services/data.service';

@Component({
  selector: 'app-mapbox-popup',
  standalone: true,
  //imports: [],
  templateUrl: './mapbox-popup.component.html',
  styleUrl: './mapbox-popup.component.css'
})
export class MapboxPopupComponent {
  @Input() data: any; // Data for the popup
  @Output() onClosePopup = new EventEmitter<void>(); // Event to close the popup
  @Output() onClickDetails: EventEmitter<number> = new EventEmitter();
  @Output() onClickNewIncident: EventEmitter<string> = new EventEmitter();
  @Output() onClickPasportDetails: EventEmitter<string> = new EventEmitter();

  constructor(public dataService: DataService) {}

  ngOnInit() {
    console.log('Popup data', this.data);
  }

  closePopup() {
    this.onClosePopup.emit();
  }

  showDetails(id: number) {
    this.onClickDetails.emit(id);
  }

  showNewIncidentForm(uuid: string) {
    console.log('Show new incident form for device', uuid);
    this.onClickNewIncident.emit(uuid);
  }

  showPasportDetails(uuid: string) {
    console.log('Show pasport details for device', uuid);
    this.onClickPasportDetails.emit(uuid);
  }

}
