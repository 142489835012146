import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet, Router } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { OnInit } from '@angular/core';
import { initFlowbite } from 'flowbite';
import { NotificationService } from '@services/notification.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, MatToolbarModule],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private notificationService: NotificationService
  ) {
    this.notificationService.requestPermission();
  }

  title = 'web-app';

  navigateTo(path: string) {
    this.router.navigate([path]);
  }

  ngOnInit(): void {
    initFlowbite();
  }
}
