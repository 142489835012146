import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
class PermissionsService {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this.authService.isLoggedIn()) {
      const refreshed = await this.authService.renewWithRefreshToken();
      if (!refreshed) {
        this.router.navigate(['/signin']);
        return false;
      }
    }
    return true;
  }
}

export const AuthGuard: CanActivateFn = async (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Promise<boolean> => {
  return await inject(PermissionsService).canActivate(next, state);
}
