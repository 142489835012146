import { Injectable } from '@angular/core';
import { PasportTemplateDictionary } from '@app/shared/interfaces/pasport-template.interface';
import { BehaviorSubject } from 'rxjs';

// interface for map filter checkbox states. we can add more components here
// TODO: check if we want to move this interface to a separate file
export interface MapFilterCheckboxStates {
  last24h: boolean;
  highImportance: boolean;
  activeReports: boolean;
  completedReports: boolean;
  sourceDvo: boolean;
  sourcePublic: boolean;
  sourceOperator: boolean;
  myIncidentsAndTasks: boolean;
  statusNew: boolean;
  statusInprogress: boolean;
  statusPending: boolean;
  statusSolved: boolean;
  statusUnresolved: boolean;
  statusTerminated: boolean;
  location: boolean;
  rvo: boolean;
  rvoc: boolean;
  phase: boolean;
  lightline: boolean;
  lightplace: boolean;
  lightpoint: boolean;
  trafficCounter: boolean;
  socket: boolean;
  smartButton: boolean;
  surfaceTemperatureSensor: boolean;
  speedometer: boolean;
  motionSensor: boolean;
  floodSensor: boolean;
  sensorModule: boolean;
  agSensor: boolean;
  shmu: boolean;
  uniza: boolean;

  powerSupplyFailure: boolean;
  lightingFailure: boolean;
  dimmingFailure: boolean;
  communicationFailure: boolean;
  deviceFailure: boolean;
  configurationFailure: boolean;
  operationalIssue: boolean;
  appearanceIssue: boolean;
  otherIssue: boolean;
  devicetypeLocation: boolean;
  devicetypeRvo: boolean;
  devicetypePhase: boolean;
  devicetypeLightline: boolean;
  devicetypeLightplace: boolean;
  devicetypeLightpoint: boolean;
  devicetypeTrafficCounter: boolean;
}

export interface MapFilterOrderState {
  orderField: 'name' | 'evidenceDate' | 'lastUpdateDate' | 'state';
  orderDirection: 'asc' | 'desc';
}

export interface MapSelectedPasportItem {
  uuid: string;
  type: string;
}

export interface MarkerAttributes {
  color: string;
  iconSize: number;
}

@Injectable({
  providedIn: 'root'
})
export class StateManagementService {
  private user: BehaviorSubject<string> = new BehaviorSubject<string>('osvetlenie@tsmza.sk');
  private mapFilterCheckboxStates: BehaviorSubject<MapFilterCheckboxStates>;
  private mapFilterOrderState: BehaviorSubject<MapFilterOrderState>;
  private mapFilterFulltext: BehaviorSubject<string> = new BehaviorSubject<string>('');
  // TODO: should this be part of data.service instead of state management service?
  private mapFilterIncidentsPaging: BehaviorSubject<string> = new BehaviorSubject<string>('');
  // TODO: hardcoded value for testing
  private settingsRecordsPerPage: BehaviorSubject<number> = new BehaviorSubject<number>(25);
  // settings for mapbox 3D terrain
  private mapSettings3DTerrain: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private pasportFilterRvos: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  private pasportTemplateDictionary: PasportTemplateDictionary = {};

  // TODO: decide if we want to use BehaviorSubject for markerAttributes
  private markersAttributes: Record<string, MarkerAttributes>;
  private markersClustering: boolean;

  public getDefaultState(): MapFilterCheckboxStates {
    return {
      last24h: false,
      highImportance: false,
      activeReports: true,
      completedReports: true,
      sourceDvo: true,
      sourcePublic: true,
      sourceOperator: true,
      myIncidentsAndTasks: false,
      statusNew: true,
      statusInprogress: true,
      statusPending: true,
      statusSolved: true,
      statusUnresolved: true,
      statusTerminated: true,
      location: true,
      rvo: true,
      rvoc: true,
      phase: true,
      lightline: true,
      lightplace: true,
      lightpoint: true,
      trafficCounter: true,
      socket: true,
      smartButton: true,
      surfaceTemperatureSensor: true,
      speedometer: true,
      motionSensor: true,
      floodSensor: true,
      sensorModule: true,
      agSensor: true,
      shmu: true,
      uniza: true,

      powerSupplyFailure: true,
      lightingFailure: true,
      dimmingFailure: true,
      communicationFailure: true,
      deviceFailure: true,
      configurationFailure: true,
      operationalIssue: true,
      appearanceIssue: true,
      otherIssue: true,
      devicetypeLocation: true,
      devicetypeRvo: true,
      devicetypePhase: true,
      devicetypeLightline: true,
      devicetypeLightplace: true,
      devicetypeLightpoint: true,
      devicetypeTrafficCounter: true
    };
  }
  public getDefaultOrderState(): MapFilterOrderState {
    return {
      orderField: 'evidenceDate',
      orderDirection: 'asc'
    };
  }

  constructor() {
    const initialState = this.loadInitialStateFromLocalstorage();
    this.mapFilterCheckboxStates = new BehaviorSubject<MapFilterCheckboxStates>(initialState);
    const initialOrderState = this.loadInitialStateOrderFromLocalstorage();
    this.mapFilterOrderState = new BehaviorSubject<MapFilterOrderState>(initialOrderState);
    const initialMapboxSettings = localStorage.getItem('mapboxSettings');
    this.setMapSettings3DTerrain(initialMapboxSettings ? JSON.parse(initialMapboxSettings).mapSettings3DTerrain : false);
    // TODO: should we get initial value for page from localstorage?

    this.markersAttributes = this.loadMarkersAttributesFromLocalStorage();
    this.markersClustering = this.loadMarkersClusteringFromLocalStorage();
  }

  getUser() {
    return this.user.asObservable();
  }
  getMapFilterCheckboxStates() {
    return this.mapFilterCheckboxStates.asObservable();
  }
  getMapFilterOrderState() {
    return this.mapFilterOrderState.asObservable();
  }
  getMapFilterFulltextState() {
    return this.mapFilterFulltext.asObservable();
  }
  getMapFilterIncidentsPagingState() {
    return this.mapFilterIncidentsPaging.asObservable();
  }
  getSettingsRecordsPerPage() {
    return this.settingsRecordsPerPage.asObservable();
  }
  getMapSettings3DTerrain() {
    return this.mapSettings3DTerrain.asObservable();
  }
  getPasportFilterRvos() {
    return this.pasportFilterRvos.asObservable();
  }

  setUser(value: string) {
    this.user.next(value);
  }
  setMapFilterCheckboxState(key: keyof MapFilterCheckboxStates, value: boolean) {
    const currentStates = this.mapFilterCheckboxStates.getValue();
    currentStates[key] = value;
    this.mapFilterCheckboxStates.next(currentStates);
    this.saveStateToLocalstorage(currentStates);
  }
  setMapFilterOrderState(orderField: 'name' | 'evidenceDate' | 'lastUpdateDate' | 'state', orderDirection: 'asc' | 'desc') {
    const currentOrderState = this.mapFilterOrderState.getValue();
    currentOrderState.orderField = orderField;
    currentOrderState.orderDirection = orderDirection;
    this.mapFilterOrderState.next(currentOrderState);
    this.saveOrderStateToLocalstorage(currentOrderState);
  }
  setMapFilterFulltextState(value: string) {
    this.mapFilterFulltext.next(value);
  }
  setMapFilterIncidentsPagingState(value: string) {
    this.mapFilterIncidentsPaging.next(value);
  }
  setSettingsRecordsPerPage(value: number) {
    this.settingsRecordsPerPage.next(value);
  }
  setMapSettings3DTerrain(value: boolean) {
    this.mapSettings3DTerrain.next(value);
    this.saveMapboxSettingsToLocalstorage();
  }
  setPasportFilterRvos(value: string[]) {
    this.pasportFilterRvos.next(value);
  }

  getPasportTemplateDictionary(): PasportTemplateDictionary {
    return this.pasportTemplateDictionary;
  }
  setPasportTemplateDictionary(dictionary: PasportTemplateDictionary): void {
    this.pasportTemplateDictionary = dictionary;
  }

  private saveStateToLocalstorage(state: MapFilterCheckboxStates): void {
    localStorage.setItem('mapFilterCheckboxStates', JSON.stringify(state));
  }
  private saveOrderStateToLocalstorage(state: MapFilterOrderState): void {
    localStorage.setItem('mapFilterOrderState', JSON.stringify(state));
  }
  private saveMapboxSettingsToLocalstorage(): void {
    localStorage.setItem('mapboxSettings', JSON.stringify(
      {
        mapSettings3DTerrain: this.mapSettings3DTerrain.getValue()
      }
    ));
  }

  private loadInitialStateFromLocalstorage(): MapFilterCheckboxStates {
    const savedState = localStorage.getItem('mapFilterCheckboxStates');
    const defaultState = this.getDefaultState();
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      return { ...defaultState, ...parsedState };
    } else {
      return defaultState;
    }
  }
  private loadInitialStateOrderFromLocalstorage(): MapFilterOrderState {
    const savedState = localStorage.getItem('mapFilterOrderState');
    return savedState ? JSON.parse(savedState) : this.getDefaultOrderState();
  }


  /**
   * Retrieves the current markers attributes.
   * @returns {Record<string, MarkerAttributes>} An object containing marker attributes for each device type.
   */
  getMarkersAttributes(): Record<string, MarkerAttributes> {
    return this.markersAttributes;
  }

  /**
   * Sets the attributes for a specific device type marker.
   * @param {string} deviceType - The type of device.
   * @param {MarkerAttributes} attributes - The attributes to set for the marker.
   */
  setMarkersAttributes(deviceType: string, attributes: MarkerAttributes): void {
    this.markersAttributes[deviceType] = attributes;
    this.saveMarkersAttributesToLocalStorage();
  }

  /**
   * Loads marker attributes from local storage, combining with default attributes if necessary.
   * @returns {Record<string, MarkerAttributes>} The loaded or default marker attributes.
   * @private
   */
  private loadMarkersAttributesFromLocalStorage(): Record<string, MarkerAttributes> {
    let savedAttributes = localStorage.getItem('markersAttributes');
    if (savedAttributes) {
      const defaultAttributes = this.getDefaultMarkersAttributes();
      const savedAttributesObj = JSON.parse(savedAttributes);
      this.markersAttributes = { ...defaultAttributes, ...savedAttributesObj };
      this.saveMarkersAttributesToLocalStorage();
      return this.markersAttributes;
    } else {
      return this.getDefaultMarkersAttributes();
    }
  }

  /**
   * Retrieves the current markers clustering setting.
   * @returns {boolean} True if markers clustering is enabled, false otherwise.
   */
  getMarkersClustering(): boolean {
    return this.markersClustering;
  }

  /**
   * Sets the markers clustering setting and saves it to local storage.
   * @param {boolean} value - The clustering setting to apply.
   */
  setMarkersClustering(value: boolean): void {
    this.markersClustering = value;
    this.saveMarkersClusteringToLocalStorage();
  }

  /**
   * Loads the markers clustering setting from local storage.
   * @returns {boolean} The loaded clustering setting or the default value.
   * @private
   */
  private loadMarkersClusteringFromLocalStorage(): boolean {
    try {
      const savedClustering = localStorage.getItem('markersClustering');
      if (savedClustering) {
        const parsedValue = JSON.parse(savedClustering);
        return parsedValue === true;
      } else {
        return this.getDefaultMarkersClustering();
      }
    } catch (error) {
      return this.getDefaultMarkersClustering();
    }
  }

  /**
   * Saves the current markers attributes to local storage.
   * @private
   */
  private saveMarkersAttributesToLocalStorage(): void {
    localStorage.setItem('markersAttributes', JSON.stringify(this.markersAttributes));
  }

  /**
   * Saves the current markers clustering setting to local storage.
   * @private
   */
  private saveMarkersClusteringToLocalStorage(): void {
    localStorage.setItem('markersClustering', JSON.stringify(this.markersClustering));
  }

  /**
   * Provides the default markers attributes.
   * @returns {Record<string, MarkerAttributes>} The default markers attributes.
   * @private
   */
  private getDefaultMarkersAttributes(): Record<string, MarkerAttributes> {
    return {
      location: { color: '#FF0000', iconSize: 12 },
      rvo: { color: '#FF0000', iconSize: 12 },
      rvoc: { color: '#FF0000', iconSize: 12 },
      phase: { color: '#FF0000', iconSize: 12 },
      lightline: { color: '#FF0000', iconSize: 12 },
      lightpoint: { color: '#00FF00', iconSize: 10 },
      lightplace: { color: '#0000FF', iconSize: 10 },
      trafficCounter: { color: '#7b1fa2', iconSize: 9 },
      speedometer: { color: '#7b1fa2', iconSize: 9 },
      socket: { color: '#78909c', iconSize: 9 },
      smartButton: { color: '#009688', iconSize: 9 },
      motionSensor: { color: '#009688', iconSize: 9 },
      sensorModule: { color: '#009688', iconSize: 9 },
      agSensor: { color: '#ffca28', iconSize: 10 },
      shmu: { color: '#ec407a', iconSize: 9 },
      uniza: { color: '#ec407a', iconSize: 9 }
    };
  }

  /**
   * Provides the default markers clustering setting.
   * @returns {boolean} The default clustering setting (true).
   * @private
   */
  private getDefaultMarkersClustering(): boolean {
    return true;
  }

}
