import { Component } from '@angular/core';
import { DataService } from '@app/services/data.service';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { CommonModule, NgSwitch } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ApiResponsePostPasportEntity, PasportEntity, PasportTemplateEntity } from '@app/shared/interfaces/pasport-template.interface';

@Component({
  selector: 'app-pasport-new-device',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule, NgSwitch],
  templateUrl: './pasport-new-device.component.html',
  styleUrl: './pasport-new-device.component.css'
})
export class PasportNewDeviceComponent {

  searchForm: FormGroup = new FormGroup({});
  inputMapFilterFulltext: FormControl = new FormControl('');

  templates: PasportTemplateEntity[] = [];
  selectedTemplateId: number | null = null;
  selectedTemplate: PasportTemplateEntity | null = null;
  selectedParentUuid: string | null = null;
  selectedParentUuidManual: string | null = null;
  selectedParentControlUuid: string | null = null;
  selectedParentUuidHierarchy: string | null = null;
  paramValues: { [key: string]: any } = {};
  parentSearch: string = '';
  parentControlSearch: string = '';
  //entities: PasportEntity[] = [];
  controlEntities: PasportEntity[] = [];
  controlEntitiesManual: PasportEntity[] = [];
  lightnetEntities: PasportEntity[] = [];

  pasportHierarchyControl: PasportEntity[] = [];
  predefinedHierarchy: {
    entityType: {
      id: number,
      type: string
    },
    pasportEntity: PasportEntity | null
  }[] = [];
  pasportHierarchyLightnet: PasportEntity[] = [];

  checkboxStatesParentSearch = {
    all: true,
    location: true,
    rvo: true,
    rvoc: true,
    lightline: true,
    output: true,
    lightplace: true,
    lc: true,
    lightpoint: true
  }

  newEntity: PasportEntity | null = null;
  relations: {
    id: number;
    ancestor: PasportEntity;
    descendant: PasportEntity;
    type: string;
  }[] = [];

  selectedRelationType: string | null = null;
  nextPredefinedHierarchyItemType: string | undefined;

  // TODO: temporary hardcoded array
  allowedRelations = [
    {
        "type": "control",
        "ancestor_entity_type_id": 1,
        "descendant_entity_type_id": 1
    },
    {
        "type": "control",
        "ancestor_entity_type_id": 1,
        "descendant_entity_type_id": 2
    },
    {
        "type": "control",
        "ancestor_entity_type_id": 2,
        "descendant_entity_type_id": 3
    },
    {
        "type": "control",
        "ancestor_entity_type_id": 3,
        "descendant_entity_type_id": 4
    },
    {
        "type": "control",
        "ancestor_entity_type_id": 3,
        "descendant_entity_type_id": 5
    },
    {
        "type": "control",
        "ancestor_entity_type_id": 4,
        "descendant_entity_type_id": 5
    },
    {
        "type": "lightnet",
        "ancestor_entity_type_id": 1,
        "descendant_entity_type_id": 1
    },
    {
        "type": "lightnet",
        "ancestor_entity_type_id": 1,
        "descendant_entity_type_id": 2
    },
    {
        "type": "lightnet",
        "ancestor_entity_type_id": 2,
        "descendant_entity_type_id": 12
    },
    {
        "type": "lightnet",
        "ancestor_entity_type_id": 3,
        "descendant_entity_type_id": 13
    },
    {
        "type": "lightnet",
        "ancestor_entity_type_id": 12,
        "descendant_entity_type_id": 3
    },
    {
        "type": "lightnet",
        "ancestor_entity_type_id": 13,
        "descendant_entity_type_id": 14
    },
    {
        "type": "lightnet",
        "ancestor_entity_type_id": 14,
        "descendant_entity_type_id": 5
    }
  ];
  allowedHierarchies = [
    // location
    {
      "type": "control",
      "entityTypeId": 1,
      "hierarchyIds": [1],
      "hierarchyTypes": ["location"]
    },
    // rvo
    {
      "type": "control",
      "entityTypeId": 2,
      "hierarchyIds": [1],
      "hierarchyTypes": ["location"]
    },
    // lightline
    {
      "type": "control",
      "entityTypeId": 3,
      "hierarchyIds": [1, 2],
      "hierarchyTypes": ["location", "rvo"]
    },
    {
      "type": "lightnet",
      "entityTypeId": 3,
      "hierarchyIds": [1, 2, 12],
      "hierarchyTypes": ["location", "rvo", "rvoc"]
    },
    // lightplace
    {
      "type": "control",
      "entityTypeId": 4,
      "hierarchyIds": [1, 2, 3],
      "hierarchyTypes": ["location", "rvo", "lightline"]
    },
    // lightpoint
    {
      "type": "control",
      "entityTypeId": 5,
      "hierarchyIds": [1, 2, 3, 4],
      "hierarchyTypes": ["location", "rvo", "lightline", "lightplace"]
    },
    {
      "type": "lightnet",
      "entityTypeId": 5,
      "hierarchyIds": [1, 2, 12, 3, 13, 14],
      "hierarchyTypes": ["location", "rvo", "rvoc", "lightline", "output", "lc"]
    },
    // lc
    {
      "type": "lightnet",
      "entityTypeId": 14,
      "hierarchyIds": [1, 2, 12, 3, 13],
      "hierarchyTypes": ["location", "rvo", "rvoc", "lightline", "output"]
    }
  ]


  constructor(public dataService: DataService) {}

  ngOnInit() {
    console.log('Initializing pasport new device');
    this.initializeData();
  }

  ngOnDestroy() {
    console.log('Destroying pasport new device');
  }

  async initializeData() {
    // Fetch data or perform other initialization tasks here
    console.log('Initializing data for new device');
    this.selectedTemplateId = null;
    this.onTemplateChange(null);
    this.newEntity = null;
    this.predefinedHierarchy = [];
    this.relations = [];
    this.selectedRelationType = null;
    this.nextPredefinedHierarchyItemType = undefined;
    this.parentControlSearch = '';
    this.parentSearch = '';

    this.templates = await this.dataService.fetchApiGetPasportTemplates();
    this.templates.forEach(template => {
      delete template.data.params['uuid'];
      delete template.data.params['entityType'];
      delete template.data.params['reactivePowerCompensationType'];
      delete template.data.params['importUUID'];
      delete template.data.params['pasportType'];
    });
    console.log('templates', this.templates);
  }

  onTemplateChange(newValue: any) {
    // find specific template based on id
    //this.entities = [];
    this.pasportHierarchyControl = [];
    this.pasportHierarchyLightnet = [];
    this.controlEntities = [];
    this.controlEntitiesManual = [];
    this.lightnetEntities = [];

    this.selectedTemplate = this.templates.find(template => template.id === newValue) ?? null;
    this.resetParams();

    // TODO: refactor this ifs
    // turn off all checkbox states
    this.checkboxStatesParentSearch = {
      all: false,
      location: false,
      rvo: false,
      rvoc: false,
      lightline: false,
      output: false,
      lightplace: false,
      lc: false,
      lightpoint: false
    };

    // turn on checkbox states based on template type
    if (this.selectedTemplate?.entityType.type === 'location') {
      this.checkboxStatesParentSearch.location = true;
    }
    if (this.selectedTemplate?.entityType.type === 'rvo') {
      this.checkboxStatesParentSearch.location = true;
    }
    if (this.selectedTemplate?.entityType.type === 'lightline') {
      this.checkboxStatesParentSearch.rvo = true;
    }
    if (this.selectedTemplate?.entityType.type === 'lightplace') {
      this.checkboxStatesParentSearch.lightline = true;
      this.checkboxStatesParentSearch.rvo = true;
    }
    if (this.selectedTemplate?.entityType.type === 'lightpoint') {
      this.checkboxStatesParentSearch.rvo = true;
      this.checkboxStatesParentSearch.lightplace = true;
      this.checkboxStatesParentSearch.lc = true;
      this.checkboxStatesParentSearch.rvoc = true;
      this.checkboxStatesParentSearch.lightline = true;
      this.checkboxStatesParentSearch.output = true;
    }

    // add first item to pasport hierarchy control - if empty, it only initializes select element
    this.addPasportHierarchyControlItem();
  }

  private resetParams() {
    this.paramValues = {};
    if (this.selectedTemplate?.data?.params) {
      for (const key in this.selectedTemplate.data.params) {
        this.paramValues[key] = null;
      }
    }
  }

  objectEntries(obj: any): [string, any][] {
    return obj ? Object.entries(obj) : [];
  }

  get isValid(): boolean {
    return true;
  }

  /**
   * Creates a new device based on the selected template and parent.
   *
   * This method performs the following steps:
   * 1. Logs the parameter values and selected parent UUID.
   * 2. Checks if the selected template has valid entityType id and type.
   * 3. If valid, calls the API to create a new entity.
   * 4. Logs the created device.
   * 5. If the template is not valid, logs an error.
   *
   * @async
   * @returns {Promise<void>}
   * @throws {Error} If the API call fails.
   */
  async createDevice(): Promise<void> {
    console.log('Creating device', this.paramValues);
    console.log('Selected parent uuid', this.selectedParentUuid);
    // create new variable from params stripped of null values
    let paramValues = Object.fromEntries(Object.entries(this.paramValues).filter(([_, v]) => v !== null));
    console.log('paramValues', paramValues);

    if (this.selectedTemplate?.entityType?.id && this.selectedTemplate?.entityType?.type) {
      // call api to create entity
      const newEntityResult: ApiResponsePostPasportEntity = await this.dataService.fetchApiPostPasportEntity(
        this.selectedParentUuid,
        this.selectedTemplate.entityType.id,
        this.selectedTemplate.entityType.type,
        paramValues
      );

      if (newEntityResult) {
        this.newEntity = newEntityResult.entity;
      }
    } else {
      console.error('Selected template is not valid');
    }
  }

  async searchEntities() {
    // TODO: hardcoded valus and ifs should be refactored
    let types = '';
    // TODO: .all not used anymore?
    if (this.checkboxStatesParentSearch.all) {
      types = 'lightplace,lightpoint,trafficCounter,other,phase,breaker,rvoc,output,lc,location,rvo,lightline,crossing,twilightSwitch';
    } else {
      if (this.checkboxStatesParentSearch.location) {
        types += 'location,';
      }
      if (this.checkboxStatesParentSearch.rvo) {
        types += 'rvo,';
      }
      if (this.checkboxStatesParentSearch.lightline) {
        types += 'lightline,';
      }
      if (this.checkboxStatesParentSearch.lightplace) {
        types += 'lightplace,';
      }
      if (this.checkboxStatesParentSearch.lightpoint) {
        types += 'lightpoint,';
      }
      // remove last comma
      types = types.slice(0, -1);
    }

    this.lightnetEntities = [];
    // FIXME: hardcoded types
    const params = {
      types: types,
      jsonSearchCriteriaList: {
        "dataOption": "all",
        "searchCriteriaList": [
          { "filterKey": "name", "operation": "cn", "value": this.parentSearch }
        ]
      }
    };
    const result = await this.dataService.fetchApiGetPasportEntitiesByParams(params);
    console.log('result', result);
    this.lightnetEntities = result.entities.content;
  }

  async searchControlEntitiesManual() {
    console.log('Searching control entities', this.parentControlSearch);
    let types = '';
    // TODO: .all not used anymore?
    if (this.checkboxStatesParentSearch.all) {
      types = 'lightplace,lightpoint,trafficCounter,other,phase,breaker,rvoc,output,lc,location,rvo,lightline,crossing,twilightSwitch';
    } else {
      if (this.checkboxStatesParentSearch.location) {
        types += 'location,';
      }
      if (this.checkboxStatesParentSearch.rvo) {
        types += 'rvo,';
      }
      if (this.checkboxStatesParentSearch.rvoc) {
        types += 'rvoc,';
      }
      if (this.checkboxStatesParentSearch.lightline) {
        types += 'lightline,';
      }
      if (this.checkboxStatesParentSearch.output) {
        types += 'output,';
      }
      if (this.checkboxStatesParentSearch.lc) {
        types += 'lc,';
      }
      if (this.checkboxStatesParentSearch.lightplace) {
        types += 'lightplace,';
      }
      if (this.checkboxStatesParentSearch.lightpoint) {
        types += 'lightpoint,';
      }
      // remove last comma
      types = types.slice(0, -1);
    }

    this.controlEntitiesManual = [];
    // FIXME: hardcoded types
    const params = {
      types: types,
      jsonSearchCriteriaList: {
        "dataOption": "all",
        "searchCriteriaList": [
          { "filterKey": "name", "operation": "cn", "value": this.parentControlSearch }
        ]
      }
    };
    const result = await this.dataService.fetchApiGetPasportEntitiesByParams(params);
    console.log('result', result);
    this.controlEntitiesManual = result.entities.content;
  }

  async addPasportHierarchyControlItem() {
    console.log('Adding pasport hierarchy control item', this.selectedParentControlUuid);

    this.parentControlSearch = '';
    this.lightnetEntities = [];

    if (this.controlEntities.length <= 0) {
      this.controlEntities = [];
      // FIXME: hardcoded id
      const result = await this.dataService.fetchApiGetPasportRelationsDescendants(1);
      this.controlEntities = result.descendants;
    } else {
      // clear control hierarchy
      // this.pasportHierarchyControl = []
      // add selected item to pasport hierarchy control array
      const selectedItem = this.controlEntities.find(item => item.uuid === this.selectedParentControlUuid);
      if (selectedItem) {
        this.pasportHierarchyControl.push(selectedItem);

        // recreate control hierarchy to fetch ancestors of added item in loop
        // let result = await this.dataService.fetchApiGetPasportRelationsAncestors(selectedItem.id);
        // let counter = 0;
        // while (result.ancestors.length > 0 && counter < 10) {
        //   counter++;
        //   // add all ancestors to the top of the array
        //   for (const ancestor of result.ancestors) {
        //     // add ancestors to the top of the array
        //     this.pasportHierarchyControl.unshift(ancestor);
        //   }
        //   result = await this.dataService.fetchApiGetPasportRelationsAncestors(result.ancestors[0].id);
        // }
      }
      this.controlEntities = [];
      // FIXME: hardcoded types
      const result = await this.dataService.fetchApiGetPasportRelationsDescendants(this.pasportHierarchyControl[this.pasportHierarchyControl.length - 1].id);
      this.controlEntities = result.descendants;
      this.selectedParentControlUuid = null;
    }
  }

  async addPasportHierarchyPredefinedItemManual() {
    this.parentControlSearch = '';

    // clear hierarchy
    //this.pasportHierarchyControl = []

    // remove all entity items from predefined hierarchy
    for (const item of this.predefinedHierarchy) {
      item.pasportEntity = null;
    }

    // add selected item to pasport hierarchy control array
    const selectedItem = this.controlEntitiesManual.find(item => item.uuid === this.selectedParentUuidManual);

    // we have found item
    if (selectedItem) {
      // try to fit selected item into predefined hierarchy
      const predefinedItem = this.predefinedHierarchy.find(item => item.entityType.type === selectedItem.entityType.type);
      if (predefinedItem) {
        predefinedItem.pasportEntity = selectedItem;

        // recreate control hierarchy to fetch ancestors of added item in loop
        let result = await this.dataService.fetchApiGetPasportRelationsAncestors(selectedItem.id);
        let counter = 0;
        while (result.ancestors.length > 0 && counter < 20) {
          counter++;
          // add all ancestors to the top of the array
          for (const ancestor of result.ancestors) {
            // try to fit ancestor into predefined hierarchy
            const predefinedAncestor = this.predefinedHierarchy.find(item => item.entityType.type === ancestor.entityType.type);
            if (predefinedAncestor) {
              predefinedAncestor.pasportEntity = ancestor;
            }
          }

          if (result.ancestors[0].entityType.type === 'location') {
            break;
          }
          result = await this.dataService.fetchApiGetPasportRelationsAncestors(result.ancestors[0].id);
        }
      }
    }

    // // cleanup for next use
    this.controlEntities = [];
    this.resetPredefinedHierarchyItem(null);
  }

  async addPasportHierarchyLightnetItem() {
    console.log('Adding pasport hierarchy lightnet item', this.selectedParentUuid);
  }

  async resetPasportHierarchyControlItem(item: PasportEntity) {
    // remove item itself and all items that are after the item as a parameter
    this.pasportHierarchyControl = this.pasportHierarchyControl.slice(0, this.pasportHierarchyControl.indexOf(item) );
    // recreate controlEntities
    this.controlEntities = [];
    // get last item from pasportHierarchyControl
    if (this.pasportHierarchyControl.length <= 0) {
      this.controlEntities = [];
      // FIXME: hardcoded id
      const result = await this.dataService.fetchApiGetPasportRelationsDescendants(1);
      this.controlEntities = result.descendants;
    } else {
      const lastItem = this.pasportHierarchyControl[this.pasportHierarchyControl.length - 1];
      const result = await this.dataService.fetchApiGetPasportRelationsDescendants(lastItem.id);
      this.controlEntities = result.descendants;
    }
  }

  // Add this new method
  constructRelationHierarchy(type: string, descendantEntityTypeId: number): any[] {
    const hierarchy: any[] = [];

    const findRelations = (currentTypeId: number): void => {
      // Check if we already have this type in the hierarchy
      if (hierarchy.some(item => item.descendantTypeId === currentTypeId)) {
        return; // Stop processing to prevent infinite loop
      }

      const currentRelation = this.allowedRelations.find(
        relation => relation.type === type && relation.descendant_entity_type_id === currentTypeId
      );

      if (currentRelation) {
        const newItem = {
          type: currentRelation.type,
          ancestorTypeId: currentRelation.ancestor_entity_type_id,
          descendantTypeId: currentRelation.descendant_entity_type_id
        };

        hierarchy.unshift(newItem);

        // Recursively find the next relation
        findRelations(currentRelation.ancestor_entity_type_id);
      }
    };

    findRelations(descendantEntityTypeId);
    return hierarchy;
  }

  // Update your existing method to use the new function
  async onPredefinedRelationTypeChange(event: any) {
    // TODO: remove hardcoded 5 after testing
    const newEntityTypeId = this.newEntity?.entityType?.id ?? 5; // Fallback to 5 if not set
    const relationType = this.selectedRelationType;

    if (!relationType) {
      this.predefinedHierarchy = [];
      return;
    }

    // find allowed hierarchies from config array allowedHierarchies
    const allowedHierarchies = this.allowedHierarchies.filter(hierarchy => hierarchy.type === relationType && hierarchy.entityTypeId === newEntityTypeId);
    if (!(allowedHierarchies.length > 0)) {
      console.error('No allowed hierarchies found');
      this.predefinedHierarchy = [];
      return;
    }

    this.controlEntities = [];
    this.predefinedHierarchy = [];
    // TODO: what to do if there are multiple allowed hierarchies for a specific entity type?
    // loop allowedHierarchies and construct predefined hierarchy array
    // TODO: hardcoded first allowed hierarchy
    for (let i = 0; i < allowedHierarchies[0].hierarchyIds.length; i++) {
      const entityTypeId = allowedHierarchies[0].hierarchyIds[i];
      const entityType = allowedHierarchies[0].hierarchyTypes[i];
      this.predefinedHierarchy.push({
        entityType: {
          id: entityTypeId,
          type: entityType
        },
        pasportEntity: null
      });
    }

    this.setNextPredefinedHierarchyItem();
    this.addPasportEntityToPredefinedHierarchy();
  }

  async addPasportEntityToPredefinedHierarchy() {
    console.log('Adding pasport entity to predefined hierarchy', this.selectedParentControlUuid);

    if (this.controlEntities.length <= 0) {
      this.controlEntities = [];
      // FIXME: hardcoded id
      const result = await this.dataService.fetchApiGetPasportRelationsDescendants(1);
      this.controlEntities = result.descendants;
      this.setNextPredefinedHierarchyItem();
    } else {
      // find selected item
      const selectedItem = this.controlEntities.find(item => item.uuid === this.selectedParentControlUuid);
      if (selectedItem) {
        // try to find item in predefined hierarchy by entity type
        const predefinedItem = this.predefinedHierarchy.find(item => item.entityType.type === selectedItem.entityType.type);
        if (predefinedItem) {
          predefinedItem.pasportEntity = selectedItem;

          this.controlEntities = [];
          // FIXME: hardcoded types
          const result = await this.dataService.fetchApiGetPasportRelationsDescendants(predefinedItem.pasportEntity.id);
          this.controlEntities = result.descendants;
        }
      }

      this.selectedParentControlUuid = null;
      this.setNextPredefinedHierarchyItem();
    }
  }

  setNextPredefinedHierarchyItem() {
    let lastFilledIndex = -1;
    for (let i = this.predefinedHierarchy.length - 1; i >= 0; i--) {
      if (this.predefinedHierarchy[i].pasportEntity !== null) {
        lastFilledIndex = i;
        break;
      }
    }

    const nextIndex = lastFilledIndex + 1;
    if (nextIndex < this.predefinedHierarchy.length) {
      this.nextPredefinedHierarchyItemType = this.predefinedHierarchy[nextIndex].entityType.type;
    } else {
      this.nextPredefinedHierarchyItemType = undefined;
    }
  }

  async resetPredefinedHierarchyItem(itemIndex: number | null) {
    console.log('Resetting predefined hierarchy item');
    console.log('itemIndex', itemIndex);
    // reset item at index and all items after it
    if (itemIndex !== null && itemIndex >= 0) {
      for (let i = itemIndex; i < this.predefinedHierarchy.length; i++) {
        this.predefinedHierarchy[i].pasportEntity = null;
      }
    }

    // find last item in predefined hierarchy that has pasportEntity
    const lastItemIndex = this.predefinedHierarchy.reduceRight((acc, item, index) => {
      return acc === -1 && item.pasportEntity !== null ? index : acc;
    }, -1);

    console.log('lastItemIndex', lastItemIndex);

    if (lastItemIndex !== -1 && this.predefinedHierarchy[lastItemIndex].pasportEntity) {
      console.log('this.predefinedHierarchy[lastItemIndex].pasportEntity', this.predefinedHierarchy[lastItemIndex].pasportEntity);
      const id = this.predefinedHierarchy[lastItemIndex]?.pasportEntity?.id;
      if (id) {
        const result = await this.dataService.fetchApiGetPasportRelationsDescendants(id);
        this.controlEntities = result.descendants;
      }
    } else {
      this.controlEntities = [];
      // FIXME: hardcoded id
      const result = await this.dataService.fetchApiGetPasportRelationsDescendants(1);
      this.controlEntities = result.descendants;
    }

    this.setNextPredefinedHierarchyItem();
  }

  async createRelationPredefined() {
    // get last item that has pasportEntity defined
    const lastItemIndex = this.predefinedHierarchy.reduceRight((acc, item, index) => {
      return acc === -1 && item.pasportEntity !== null ? index : acc;
    }, -1);

    const lastUuid = this.predefinedHierarchy[lastItemIndex]?.pasportEntity?.uuid;

    if (this.selectedRelationType && this.newEntity?.uuid && lastUuid) {
      const newEntityResult = await this.dataService.fetchApiPostPasportRelations(lastUuid, this.newEntity.uuid, this.selectedRelationType);
      if (newEntityResult && newEntityResult.id) {
        this.relations.push({
          id: newEntityResult.id,
          ancestor: newEntityResult.ancestor,
          descendant: newEntityResult.descendant,
          type: newEntityResult.type
        });
      }

      // reset search input
      this.parentSearch = '';
      this.parentControlSearch = '';

      // reset hierarchy
      this.controlEntities = [];
      this.controlEntitiesManual = [];
      this.pasportHierarchyControl = [];
      this.addPasportHierarchyControlItem();

      this.predefinedHierarchy = [];
      this.selectedRelationType = null;
      this.onPredefinedRelationTypeChange(null);
    }
  }

  async deleteRelation(relationId: number) {
    const result = await this.dataService.fetchApiDeletePasportRelation(relationId);
    if (result) {
      this.relations = this.relations.filter(relation => relation.id !== relationId);
    }
  }

}
